import { authHeader  } from '../helpers';
// headers: { ...authHeader(), 'Content-Type': 'application/json' },
export const userService = {
    login,
    logout,
    PostInfo,
    GetInfo,
    DeleteInfo,
    EditInfo,
    projectAddFolder,
    GetDocumentations,
    addImages,
    EditLink,
    DeleteUser,
    ReplaceImg,
    searchProjects,
    getProjects
};
const api_key = 'msT4sTB0Qkl@nWwVwVAlcGKczP3PntWKWMOlDnmqIIo2CVY5Nvr!899IYdvjGp0z';
const api_url = 'https://imsba.com/';
const api_version = 'api/v2/';

function login(user) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body:  JSON.stringify(user)
    };
    return fetch(`${api_url}${api_version}admin/profile/login?key=${api_key}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.token ) {
                localStorage.setItem('user_token', JSON.stringify(user.token));
                if(user.firstname && user.lastname){
                    var x1 = user.firstname.substring(0, 1)
                    var x2 = user.lastname.substring(0, 1)
                    localStorage.setItem('UserName', x1+x2);
                }
            }
            return user;
        });
}
function logout() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${api_url}${api_version}admin/profile/logout?key=${api_key}`, requestOptions)
        .then(handleResponse)
        .then( ()=>{
            localStorage.removeItem('user_token');
            localStorage.removeItem('UserName');
            }

       );
}
function PostInfo(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${api_url}${api_version}${url}${api_key}`, requestOptions).then(handleResponse);
}
function searchProjects(url, data, offset) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&offset=${offset}&limit=10`, requestOptions).then(handleResponse);
}
function addImages(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: data
    };
    return fetch(`${api_url}${api_version}${url}${api_key}`, requestOptions).then(handleResponse);
}
function GetInfo(url, data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${api_url}${api_version}${url}${api_key}`, requestOptions).then(handleResponse);
}
function getProjects(url, data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&offset=${data.offset}`, requestOptions).then(handleResponse);
}
function GetDocumentations(url, data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&id=${data}`, requestOptions).then(handleResponse);
}

function DeleteInfo(url, data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&id=${data.id}`, requestOptions).then(handleResponse);
}
function DeleteUser(url, data) {
    const formData = new FormData();
    formData.append('id', data.id);
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: formData
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&id=${data.id}`, requestOptions).then(handleResponse);
}
function EditInfo(url, data) {
    const formData = new FormData();
    formData.append('name', data.name);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: formData
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&id=${data.id}`, requestOptions).then(handleResponse);
}
function EditLink(url, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&id=${data.id}`, requestOptions).then(handleResponse);
}
function ReplaceImg(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: data
    };
    return fetch(`${api_url}${api_version}${url}${api_key}`, requestOptions).then(handleResponse);
}
function projectAddFolder(url, data) {
    const formData = new FormData();
    formData.append('folder_id', data.folder_id);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: formData
    };
    return fetch(`${api_url}${api_version}${url}${api_key}&id=${data.id}`, requestOptions).then(handleResponse);
}
// function GetInfo(url, data) {
//     const requestOptions = {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json'},
//     };
//     return fetch(`${config}${url}${data}`, requestOptions).then(handleResponse);
// }

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 406 || response.status === 401) {
                localStorage.removeItem('user_token');
                localStorage.setItem('LoginStatus', false);
                window.location.reload();
            }
            else{
                const error = data;
                return Promise.reject(error);
            }



        }

        return data;
    });
}