import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Prototype from './prototype';
import {Comments} from './comments';
import {Api} from './api';
import { history } from '../../helpers';
import {  getInfo } from '../../actions';

class Preview extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            next:'',
            prev:'',
            activeTab:'',
            leftContainer:''
        }

        // this.openTab = this.openTab.bind(this)
        this.hashHistoryListener = history.listen((location, action) => {
            if(history.location.pathname === `/project/${this.props.match.params.id}/preview/${this.props.match.params.screenid}`) {
                let link2 = location.search
                link2 = link2.replace('?', '');
                this.setState({
                    activeTab: link2
                })
            }
        })
    }

    componentDidMount(){
        let leftContainer = localStorage.getItem('leftContainer')

        if(leftContainer){
            this.setState({
                leftContainer
            })
        }
        if(history.location.pathname === `/project/${this.props.match.params.id}/preview/${this.props.match.params.screenid}`){
            let link2= history.location.search
            link2=link2.replace('?','');
            this.setState({
                activeTab:link2
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getDocumentations(this.props.match.params.id))
        document.addEventListener("keydown", this.keydown);
    }

    componentWillUnmount(){
        this.hashHistoryListener();
        document.removeEventListener('keydown',this.keydown)
    }

    keydown =(e)=>{
        switch (e.keyCode) {
            case 37:
                if(this.state.prev){
                    history.push(this.state.prev)
                }

                break;
            case 39:
                if(this.state.next){
                    history.push(this.state.next)
                }

                break;

        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.getDocumentations && nextProps.getDocumentations.getDocumentations ){
            const element = nextProps.getDocumentations.getDocumentations;
            element.forEach((item, index)=>{
                if(item.id === this.props.match.params.screenid){
                    if(element[index-1]){
                        this.setState({
                            prev: `/project/${this.props.match.params.id}/preview/${element[index-1].id}?${this.state.activeTab}`
                        })
                    }
                    else{
                        this.setState({
                            prev: '',

                        })
                    }
                    if(element[index+1]){
                        this.setState({
                            next: `/project/${this.props.match.params.id}/preview/${element[index+1].id}?${this.state.activeTab}`
                        })
                    }
                    else{
                        this.setState({
                            next: '',

                        })
                    }
                }

            })
        }
        // if(nextProps.getDocumentation.getDocumentation){
        //     this.setState({
        //         img: nextProps.getDocumentation.getDocumentation.img
        //     })
        // }
    }
    // openTab(e){
    //     const self =$(e.currentTarget)
    //     if(!self.hasClass('active')){
    //         const dataId=self.attr('data-id')
    //         this.setState({
    //             activeTab: dataId
    //         })
    //
    //     }
    // }
    leftContainer =(param)=>{
        this.setState({
            leftContainer:param
        })
    }
    render() {
        return (
            <div className="prototype">
                    <div className="header">
                        {
                            this.props.authorized ?
                                  <Fragment>
                                        <div className="left">
                                            <Link to={`/project/${this.props.match.params.id}`} className="back"><span> {'<'} </span>Back
                                                To Prototype</Link>
                                        </div>
                                        <div className="right">
                                            <div className="proto_cont_but"><Link
                                              to={`/project/${this.props.match.params.id}/screen/${this.props.match.params.screenid}?${this.state.activeTab}`}
                                              className="proto_but flex-center done_color">Edit</Link></div>
                                        </div>
                                  </Fragment>: null
                        }
                        <div className="mid mod_preview">
                            <Link to={`?prototype`}
                                  className={this.state.activeTab === 'prototype' ? "proto_but bt22 first_but flex-center active" : "proto_but bt22 first_but flex-center"}>Prototype</Link>
                            <Link to={`?comments`}
                                  className={this.state.activeTab === 'comments' ? "proto_but bt22 flex-center active" : "proto_but bt22 flex-center"}>Comments</Link>
                            <Link to={`?api`}
                                  className={this.state.activeTab === 'api' ? "proto_but bt22 flex-center active" : "proto_but bt22 flex-center"}>Api</Link>
                        </div>
                    </div>
                <div className="main_prototype flex-center">

                    {this.state.prev && <Link to={this.state.prev} className={this.state.activeTab === 'comments' || this.state.activeTab === 'api'?'prev left_px':'prev'}
                                              style={this.state.activeTab === 'comments' || this.state.activeTab === 'api'?{left:this.state.leftContainer}:{left:0}}></Link>}
                    {this.state.next && <Link to={this.state.next} className="next"></Link>}
                    {this.state.activeTab === 'prototype' &&
                        <Prototype
                                openTab={this.openTab}
                                projId={this.props.match.params.id}
                                screenId={this.props.match.params.screenid}
                        />
                    }
                    {this.state.activeTab === 'comments' &&
                         <Comments
                             authorized={this.props.authorized}
                             openTab={this.openTab}
                             projId={this.props.match.params.id}
                             screenId={this.props.match.params.screenid}
                             leftContainer={this.leftContainer}
                         />
                    }
                    {this.state.activeTab === 'api' &&
                        <Api
                            openTab={this.openTab}
                            projId={this.props.match.params.id}
                            screenId={this.props.match.params.screenid}
                            leftContainer={this.leftContainer}
                        />
                    }
                </div>


            </div>
        );
    }
}

function mapStateToProps(state) {
    const getDocumentation= state.getDocumentation
    const getDocumentations= state.getDocumentations

    return {
        getDocumentation,
        getDocumentations,

    };
}

const connectedPreview = connect(mapStateToProps)(Preview);
export {connectedPreview as Preview};
