import React from 'react';
import {Router, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {history} from '../../helpers';
import {Popup} from '../Popups'
import { PrivateRoute, PrivateRoute2, PublicRoute } from '../PrivateRoute';
import { Alert } from 'reactstrap';

import LoadingBar from 'react-redux-loading-bar'
import {Dashboard}  from '../Dashboard';
import {Login}  from '../Login';
import {Project}  from '../Project';
import {Screen}  from '../Screen';
import {Preview}  from '../Preview';


class RouteComponent extends React.Component {
    render() {
        return (
            <div>

                <LoadingBar className="loading_section1"/>
                <Popup/>
                <Alert color="success" isOpen={this.props.notification && this.props.notification.type==='notification-success'}>
                    Data saved!
                </Alert>
                <Router history={history} children={this.props.children}>

                    <div>
                        <Switch>
                            <PrivateRoute2 exact path="/" component={Login}/>
                            {/*<PrivateRoute exact path="/dashboard" component={Home} />*/}
                            {/*<Route component={Nomatch}/>*/}

                            <PrivateRoute path="/dashboard"  exact component={Dashboard}/>
                            <PrivateRoute path="/project/:id" exact component={Project}/>
                            <PrivateRoute path="/project/:id/screen/:screenid"   component={Screen}/>
                            <PublicRoute path="/project/:id/preview/:screenid"   component={Preview}/>
                            {/*<PrivateRoute3 path="/project/:id/preview/:screenid" render={(props) => (*/}
                                {/*<Preview key={props.match.params.screenid} {...props} />)*/}
                            {/*} />*/}
                        </Switch>
                    </div>
                </Router>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        notification: state.notification
    };
}

const connectedRouteComponent = connect(mapStateToProps)(RouteComponent);
export {connectedRouteComponent as RouteComponent};
