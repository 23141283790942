const initialState={
    open_modal: false,
    modal_name: '',
    close_modal: false,
}



export function modals(state = initialState, action) {
    switch (action.type){
        case "OPEN_MODAL":
            return{ open_modal: true, modal_name: action.payLoad, data: action.data}
        case "CLOSE_MODAL":
            return{ open_modal: false, modal_name: '' }
        default:
            return state
    }
}
