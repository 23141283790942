

export function notification(state = {}, action) {
  switch (action.type) {
    case 'SUCCESS-NOTIFICATION':
      return {
        type: 'notification-success',
        message: action.message
      };
    case 'ERROR-NOTIFICATION':
      return {
        type: 'notification-danger',
        message: action.message
      };
    case 'CLEAR-NOTIFICATION':
      return {};
    default:
      return state
  }
}