/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import warning from '../../img/warning.svg';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
class Fail_popup extends Component{
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    render() {

        return (
            <div  className="modal_content">
                <div className="modal_head">{this.props.modals.data &&  this.props.modals.data.error && this.props.modals.data.error.message ?this.props.modals.data.error.message:'Error' }</div>
                {/*<div className="modal_head">{this.props.modals.data}</div>*/}


                <div className="modal_bod">
                    <div className="but_cont two_but">
                        <button type="submit" className="new_proj " onClick={()=>this.close_modal()}>OK</button>
                    </div>
                </div>


            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals
    };
}
export default connect(mapStateToProps)(Fail_popup);
