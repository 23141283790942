import React from 'react';
import {connect} from 'react-redux';
import {  getInfo } from '../../actions';
import {Link} from "react-router-dom";
import $ from "jquery";
import { Scrollbars } from 'react-custom-scrollbars';
class Prototype extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list:[],
            // scroll:false,
            scale:1,
            footer:0
        }
    }

    componentDidMount() {

        this.props.getLinks(this.props.screenId)
        this.props.getDoc(this.props.screenId)
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.getDocumentation && nextProps.getImgLinks && nextProps.getImgLinks!==this.state.list){
            this.setState({
                list: nextProps.getImgLinks
            },function () {
                this.loadEnd()
            })
        }
    }
    loadEnd = (e)=>{
        let width =$('.main_img').get(0).naturalWidth;
        let height =$('.main_img').get(0).naturalHeight;
        let widthNow=$('.prev_img1').width()
        let heightNow=$('.prev_img1').height()
        let heightContainer = $('.scrollBar').height();
        let scale=widthNow/width;
        this.setState({scale:scale})
        if(heightNow>heightContainer){
            let footer = (height - this.props.getDocumentation.footer)*scale
            this.setState({scroll: 'yes', footer:footer})
        }
        else{
            this.setState({scroll: 'no'})
        }

    }
    componentDidUpdate(nextProps, nextState){
        if( nextProps.screenId !== this.props.screenId){
            this.props.getLinks(this.props.screenId)
            this.props.getDoc(this.props.screenId)
        }
    }
    showDragElement =(e)=>{
        const self=$(e.currentTarget);
        if(!$(e.target).hasClass('itemdrag1'))
            self.addClass('active')
            setTimeout(function () {
                self.removeClass('active')
            }, 500)

    }
    render() {
        return (
            <div  id="prototype_main_container" className="screen_cont_inside">
                    <div className="img-container" onClick={this.showDragElement}>
                        {this.props.getDocumentation && this.state.list  && <div className="device_container iphonex" >
                            {this.state.scroll==='yes' && this.props.getDocumentation.header &&
                                <div className="header_prev"
                                     style={{
                                         background: `url(https://imsba.com/${this.props.getDocumentation.img})`,
                                         backgroundRepeat: 'no-repeat',
                                         height: `${this.props.getDocumentation.header*this.state.scale}px`,
                                         width: '330px',
                                         backgroundPosition: 'top center',
                                         backgroundSize:'100%',
                                         marginLeft: '22px'

                                     }}
                                >
                                </div>
                            }
                            {this.state.scroll==='yes' && this.props.getDocumentation.footer &&
                                <div className="footer_prev"
                                     style={{
                                         background: `url(https://imsba.com/${this.props.getDocumentation.img})`,
                                         backgroundRepeat: 'no-repeat',
                                         height: `${this.state.footer}px`,
                                         width: '330px',
                                         backgroundPosition: 'bottom center',
                                         backgroundSize:'100%',
                                         marginLeft: '22px'

                                     }}
                                >

                                </div>
                            }
                                <Scrollbars
                                    style={{ width: 330, height: 660, marginLeft: 22 }}
                                    className="scrollBar"
                                    renderView={props => <div {...props}  className="view_prev"/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    autoHide
                                >
                                    <div className="img-container22">
                                        <img src={`https://imsba.com/${this.props.getDocumentation.img}`}
                                             alt=""
                                             className="main_img prev_img1"

                                        />

                                        { this.state.list.map((item, index) =>
                                                <Link to={`/project/${this.props.projId}/preview/${item.link_id}${window.location.search}`} className="itemdrag1" style={{width: `${item.width*this.state.scale}px`, height:`${item.height*this.state.scale}px`,left:`${item.x*this.state.scale}px`,top:`${item.y*this.state.scale}px`,}} key={item.id}></Link>
                                            )}

                                    </div>
                                    {/*</div>*/}

                                </Scrollbars>


                        </div>
                        }
                    </div>


            </div>
        );
    }
}

function mapStateToProps(state) {
    // const {getDocumentations}=state.getDocumentations
    const {getDocumentation}=state.getDocumentation
    const {getImgLinks}=state.getImgLinks
    return {
        getDocumentation,
        getImgLinks
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getDoc: (id) => {
            dispatch(getInfo.getDocumentation(id));
        },
        getLinks: (id) => {
            dispatch(getInfo.getImgLinks(id));
        }

    }
};

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(Prototype);
