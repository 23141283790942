import React from 'react';
import {connect} from 'react-redux';
import {  getInfo } from '../../actions';
import {  userActions } from '../../actions';
import {  notificationActions } from '../../actions';
import {Link} from "react-router-dom";
import { history } from '../../helpers';
import Prototype from './prototype';
import {Comments} from './comments';
import {Api} from './api';
import $ from "jquery";
class Screen extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            next:'',
            prev:'',
            docs:[],
            activeTab:'',
            images:[],
            leftContainer:''

        }
        // this.done = this.done.bind(this)
        this.done1 = this.done1.bind(this)
        this.preview = this.preview.bind(this)
        // this.openTab = this.openTab.bind(this)
        this.componentBRef = React.createRef();
        this.hashHistoryListener = history.listen((location, action) => {
            if(history.location.pathname === `/project/${this.props.match.params.id}/screen/${this.props.match.params.screenid}`) {
                let link2 = location.search
                link2 = link2.replace('?', '');
                let activeTab=this.state.activeTab;

                this.setState({
                    activeTab: link2
                })
                if(activeTab === 'prototype' && activeTab !== link2){
                    this.componentBRef.current.getWrappedInstance().doneProto('tab')
                }

            }
        })

    }

    componentDidUpdate(){

    }
    componentWillUnmount(){
        this.hashHistoryListener();
        document.removeEventListener('keyup',this.keyup)
    }
    componentDidMount(){
        let leftContainer = localStorage.getItem('leftContainer')

        if(leftContainer){
            this.setState({
                leftContainer
            })
        }
        if(history.location.pathname === `/project/${this.props.match.params.id}/screen/${this.props.match.params.screenid}`){
            let link2= history.location.search
            link2=link2.replace('?','');
            this.setState({
                activeTab:link2
            }, function () {
                console.log(this.state.activeTab)
            })
        }
        document.addEventListener("keyup", this.keyup);
        // document.onkeydown =  this.keydown
        const { dispatch } = this.props;
        dispatch(getInfo.getDocumentations(this.props.match.params.id))
    }
    keyup =(e)=>{
        // console.log($(e.target))
        if(!$(e.target).parents('.comments_container').hasClass('comments_container')){
            switch (e.keyCode) {
                case 37:
                    this.Prev()
                    break;
                case 39:
                    this.Next()
                    break;

            }
        }




    }

    preview(){
        this.componentBRef.current.getWrappedInstance().doneProto('preview');
    }
    // openTab(e){
    //     const self =$(e.currentTarget)
    //     if(!self.hasClass('active')){
    //         const dataId=self.attr('data-id')
    //         if(this.state.activeTab === 'prototype'){this.componentBRef.current.getWrappedInstance().doneProto('tab');}
    //         this.setState({
    //             activeTab: dataId
    //         })
    //
    //     }
    // }

    replaceImage = (event)=>{

        const data = new FormData();
        data.append("img", event.target.files[0]);
        data.append("name", event.target.files[0].name);
        data.append("id", this.props.match.params.screenid);
        data.append("website_id", this.props.match.params.id);
        const { dispatch } = this.props;
        dispatch(userActions.replaceImg( data, this.props.match.params.screenid ))
        // dispatch(userActions.replaceImg( {name:''}, this.props.match.params.screenid ))

    }
    componentWillReceiveProps(nextProps){
        if(nextProps.getDocumentations && nextProps.getDocumentations.getDocumentations ){
            const element = nextProps.getDocumentations.getDocumentations;
            element.forEach((item, index)=>{
                if(item.id === this.props.match.params.screenid){
                    if(element[index-1]){
                        this.setState({
                            prev: `/project/${this.props.match.params.id}/screen/${element[index-1].id}?${this.state.activeTab}`
                        })
                    }
                    else{
                        this.setState({
                            prev: '',

                        })
                    }
                    if(element[index+1]){
                        this.setState({
                            next: `/project/${this.props.match.params.id}/screen/${element[index+1].id}?${this.state.activeTab}`
                        })
                    }
                    else{
                        this.setState({
                            next: '',

                        })
                    }
                }

            })
        }
        // if(nextProps.getDocumentation.getDocumentation){
        //     this.setState({
        //         img: nextProps.getDocumentation.getDocumentation.img
        //     })
        // }
    }
    Next =()=>{
        if(this.state.next){
            if(this.state.activeTab === 'prototype'){
                this.componentBRef.current.getWrappedInstance().doneProto('arrow',this.state.next);
            }
            else{
                history.push(this.state.next)
            }
        }

    }
    Prev =()=>{
        if(this.state.prev){
            if(this.state.activeTab === 'prototype'){
                this.componentBRef.current.getWrappedInstance().doneProto('arrow',this.state.prev);
            }
            else{
                history.push(this.state.prev)
            }
        }

    }
    // done(){
    //     this.componentBRef.current.getWrappedInstance().doneProto('done');
    //
    // }
    done1(){
        // history.push(`/project/${this.props.match.params.id}`)
        const { dispatch } = this.props;
        dispatch(notificationActions.success('done protoo'))
        setTimeout(() => {
            dispatch(notificationActions.clear())
        }, 2000)
    }
    leftContainer =(param)=>{
       this.setState({
           leftContainer:param
       })
    }
    render() {
        const {getDocumentations }=this.props.getDocumentations
        return (
            <div className="prototype">
                <div className="header">
                    <div className="left">
                        <Link to={`/project/${this.props.match.params.id}`}  className="back"><span> {'<'} </span>Back To Prototype</Link>
                    </div>
                    {/*<div className="mid">*/}
                        {/*<div className={this.state.activeTab === 'prototype'?"proto_but bt22 first_but flex-center active":"proto_but bt22 first_but flex-center" }data-id="prototype" onClick={this.openTab}>Prototype</div>*/}
                        {/*<div className={this.state.activeTab === 'comments'?"proto_but bt22 flex-center active":"proto_but bt22 flex-center"} data-id="comments" onClick={this.openTab}>Comments</div>*/}
                        {/*<div className={this.state.activeTab === 'api'?"proto_but bt22 flex-center active":"proto_but bt22 flex-center"} data-id="api" onClick={this.openTab}>Api</div>*/}
                    {/*</div>*/}
                    <div className="mid">
                        <Link to={`?prototype`} className={this.state.activeTab === 'prototype'?"proto_but bt22 first_but flex-center active":"proto_but bt22 first_but flex-center" }>Prototype</Link>
                        <Link to={`?comments`} className={this.state.activeTab === 'comments'?"proto_but bt22 flex-center active":"proto_but bt22 flex-center"} >Comments</Link>
                        <Link  to={`?api`} className={this.state.activeTab === 'api'?"proto_but bt22 flex-center active":"proto_but bt22 flex-center"} >Api</Link>
                    </div>
                    <div className="right">
                        <label>
                            <input type="file" onChange={this.replaceImage}/>
                            <div className="proto_but flex-center">Replace IMG</div>
                        </label>
                        {this.state.activeTab !== 'prototype' && <Link to={`/project/${this.props.match.params.id}/preview/${this.props.match.params.screenid}?${this.state.activeTab}`}  className="proto_but flex-center prev-color">Preview</Link>}
                        {this.state.activeTab === 'prototype' && <div className="proto_but flex-center prev-color" onClick={this.preview}>Preview</div>}
                        <div className="proto_cont_but">
                            {/*{this.state.activeTab === 'prototype' && <div className="proto_but flex-center done_color" onClick={this.done}>Save</div>}*/}
                            {/*{this.state.activeTab !== 'prototype' && <div className="proto_but flex-center done_color" onClick={this.done1}  >Save</div>}*/}
                            <div className="proto_but flex-center done_color" onClick={this.done1}  >Save</div>
                        </div>
                    </div>
                </div>
                <div className="main_prototype flex-center">
                    {this.state.prev && <div onClick={this.Prev} className={this.state.activeTab === 'comments' || this.state.activeTab === 'api'?'prev left_px':'prev'}
                                             style={this.state.activeTab === 'comments' || this.state.activeTab === 'api'?{left:this.state.leftContainer}:{left:0}}>

                    </div>}
                    {this.state.next && <div onClick={this.Next} className="next"></div>}
                    {getDocumentations && this.state.activeTab === 'prototype' &&
                        <Prototype
                                    thumbs={getDocumentations}
                                    projId={this.props.match.params.id}
                                    screenId={this.props.match.params.screenid}

                                    ref={this.componentBRef} />
                    }
                    {this.state.activeTab === 'comments' &&
                     < Comments

                         openTab={this.openTab}
                         projId={this.props.match.params.id}
                         screenId={this.props.match.params.screenid}
                         leftContainer={this.leftContainer}
                     />
                    }
                    {getDocumentations && this.state.activeTab === 'api' &&
                    < Api

                        openTab={this.openTab}
                        projId={this.props.match.params.id}
                        screenId={this.props.match.params.screenid}
                        accountID={this.props.getDocumentations.getDocumentations[0].account_id}
                        leftContainer={this.leftContainer}
                        // accountID={83}
                    />
                    }
                </div>


            </div>
        );
    }
}

function mapStateToProps(state) {
    const getDocumentation= state.getDocumentation
    const getDocumentations= state.getDocumentations
    return {
        getDocumentation,
        getDocumentations,
    };
}

const connectedScreen = connect(mapStateToProps)(Screen);
export {connectedScreen as Screen};
