
export const notificationActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: 'SUCCESS-NOTIFICATION', message };
}

function error(message) {
    return { type: 'ERROR-NOTIFICATION', message };
}
function clear() {
    return { type: 'CLEAR-NOTIFICATION' };
}