import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import $ from "jquery";
import {  userActions } from '../../actions';
import {  getInfo } from '../../actions';
import {  modalActions } from '../../actions';
import play from '../../img/play-button.svg';
import logo from '../../img/logo.png';
import img_drag from '../../img/gr2.svg';
import del_white from '../../img/delete-button_white.svg';
import edit from '../../img/edit.svg';
import drag from '../../img/drag.svg';
import loader from '../../img/pulse.gif';
import {SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';


// http://imsba.s3.amazonaws.com
const DragHandle = SortableHandle(() => <span className="drag_handl "><img src={drag} alt="" width="18"/></span>);
const SortableItem = SortableElement(({name, docid, imgurl, modalOpen, webid}) =>
    <div className="item">

        <div className="info_item">{name}</div>
        <div className="item_container">
            <img src={`https://imsba.com/${imgurl}`} alt=""/>
            <div className="over_proj">
                <DragHandle />
                <div  className="over_link">
                    <Link to={`${webid}/screen/${docid}?prototype`} className="play">Prototype</Link>
                </div>
                <Link to={`${webid}/preview/${docid}?prototype`} className="over_link2 icon_cont"><img src={play}  alt="" width="12"/></Link>
                <div className="delete icon_cont" data_modal="DeleteImage" onClick={(e)=> modalOpen(e, docid)} ><img src={del_white} alt="" width="12"/></div>
            </div>
        </div>
    </div>
);

const SortableList = SortableContainer(({items, modalOpen, webid, loadImg}) => {
    return (
        <div className="proj_cont1">
            {items.length ? <div className="count_img"><span>{items.length}</span></div>:''}
            {(items.length || loadImg.length) ?
            <div className="projects_container one_proj">

                {items.map((item1, index) => (
                    <SortableItem key={`item-${index}`} index={index} name={item1.name} webid={webid} docid={item1.id} imgurl={item1.img} modalOpen={modalOpen} />
                ))}
                {loadImg && loadImg.map((item, index) => (
                    <div key={item.id} className="loading_img"><img src={loader} alt=""/></div>
                ))}
            </div>
                :<div className="empt_proj">
                    <img src={img_drag} width="150" alt=""/>
                    <div className="drag_txt">Drag and drop some files here</div>
                </div>}
        </div>
    );
});
class Project extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            images: [],
            imageUrls: [],
            message: '',
            items: [],
            projName:'',
            searchProject:'',
            loading:[],
            visibleDragArea: false
        }
        this.selectImages = this.selectImages.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
        this.logout = this.logout.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.openModal = this.openModal.bind(this)
        this.searchProject = this.searchProject.bind(this)
        this.listener1 = this.listener1.bind(this)
        this.listener3 = this.listener3.bind(this)
    }
    componentDidMount(){
        // document.addEventListener("dragover", (event) => {
        //     this.setState({
        //         visibleDragArea: true
        //     })
        // });
        // document.addEventListener("dragleave", (event) => {
        //     this.setState({
        //         visibleDragArea: false
        //     })
        // });
        const _this= this;
        document.body.addEventListener("dragover",  this.listener1);
        const area = document.querySelector('.visib-area')
        area.addEventListener("dragleave" , function listener2 (event)  {
            event.preventDefault();
            _this.setState({
                visibleDragArea: false
            })
        });
        document.body.addEventListener("drop", this.listener3);
        const { dispatch } = this.props;
        dispatch(getInfo.getProject(this.props.match.params.id))
        dispatch(getInfo.getDocumentations(this.props.match.params.id))
        //profile open close
        $('.men_but_prof').click(function () {
            $(this).next().slideToggle()
        })


    }
    listener1 =(event)=>{
        event.preventDefault();
        this.setState({visibleDragArea: true
        })
    }
    listener3 =(event)=>{
        event.stopPropagation();
        event.preventDefault();
        this.setState({
            visibleDragArea: false
        })
        this.selectImages( event.dataTransfer.files, true)

    }
    componentWillUnmount(){

         document.body.removeEventListener('dragover',this.listener1)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value

        });
    }
    searchProject(e) {
        const { name, value } = e.target;
        this.setState(state => {
            var searchValue = value.toLowerCase();
            const items = this.props.getDocumentations.getDocumentations.filter((item) => item.name.toString().toLowerCase().search(searchValue) !==-1);
            return {
                items
            };
        });
        this.setState({
            [name]: value
        });
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.getDocumentations.getDocumentations ){
            this.setState({
                items: nextProps.getDocumentations.getDocumentations,
            })
        }
        if(nextProps.getProject.getProject ){
            this.setState({
                projName: nextProps.getProject.getProject.name,
            })
        }
    }
    logout(){
        const { dispatch } = this.props;
        dispatch(userActions.logout())
    }
    selectImages(event, drop) {
        const { dispatch } = this.props;
        let images = []
        let load_img=[]
        if(!drop){
            for (let i = 0; i < event.target.files.length; i++) {
                images[i] = event.target.files.item(i);
                load_img = [...load_img, {id:i}]
            }
        }
        else{
            console.log('lenght' + event.length)
            console.log(event)
            for (let i = 0; i < event.length; i++) {
                images[i] = event.item(i);
                load_img = [...load_img, {id:i}]
            }
        }

        this.setState({
            loading:load_img
        })
        // images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
        let message = `${images.length} valid image(s) selected`
        let count = this.state.items.length;
        this.setState({images, message}, function () {
            const data = new FormData();
            // for (const file of this.state.images) {
            //     data.append('files[]', file, file.name);
            // }
            // h = exac
            console.log(this.state.images)
            this.state.images.forEach((image, index) => {
                // console.log(image)
                // console.log(image.name)
                data.append("img", image, image.name);
                data.append("name", image.name);
                data.append("website_id", this.props.match.params.id);
                data.append("sort", count);
                dispatch(userActions.addImages(data, this.props.match.params.id)).then(
                        ()=>
                        this.setState(state => {
                            return {
                                loading:this.state.loading.slice(1)
                            };
                        })

                    )

                count++;
            })


        })
    }
    onSortEnd = ({oldIndex, newIndex, collection, isKeySorting}) => {
        this.setState(
            ({items}) => (
                {
                   items: arrayMove(items, oldIndex, newIndex),
                }
            )
        )
        let sendObj =[]
        this.props.getDocumentations.getDocumentations.forEach((item, index)=>{
            let indexNew=this.state.items.findIndex(x => x.id === item.id)
            sendObj.push({id:item.id, website_id: item.website_id, old: item.sort, new: indexNew})

        })

        this.props.dispatch(userActions.sortImg({sort:JSON.stringify(sendObj)}, this.props.match.params.id))

    };
    editProjName = (e) => {
        const self=$(e.currentTarget)
        if(!self.hasClass('edit')){
            self.addClass('edit')
            $(e.currentTarget).prev().removeAttr('disabled')
            $(e.currentTarget).prev().focus()
        }

    }
    onEnter =(e)=>{
        if (e.key === 'Enter') {
            $('.edit_proj').removeClass('edit')
            $('#editProjName').attr('disabled','true')
            const { dispatch } = this.props;
            dispatch(userActions.editProjectName({id:this.props.match.params.id, name: this.state.projName}))
        }
    }
    editOnblur =(e)=>{
        if($('.edit_proj').hasClass('edit')){
            $('.edit_proj').removeClass('edit')
            $('#editProjName').attr('disabled','true')
            const { dispatch } = this.props;
            dispatch(userActions.editProjectName({id:this.props.match.params.id, name: this.state.projName}))
        }


    }
    openModal(event, data){

        event.preventDefault()
        const { dispatch } = this.props;
        dispatch(modalActions.open_modal(event.currentTarget.getAttribute('data_modal'), data))
        // dispatch()
    }
    render() {
        const username = localStorage.getItem('UserName');
        return (
            <div>
                <div className="dashboard">

                    <div className="main_dashboard">
                        <div className="head">
                            <Link to={'/dashboard'} className="logo_container">
                                <img src={logo} alt="" width="32"/>
                                <span>Reflect</span>
                            </Link>
                            <div className="right">
                                <Link to={'/dashboard'}>Projects</Link>

                                <div className="rt">
                                    <span className="men_but_prof flex-center">{username && username }</span>
                                    <div className="menu_bot">
                                        <div className="logout" onClick={this.logout}>Log Out</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search_container">
                            <div className="left">
                               <div className="categ_name">
                                <input type="text"
                                       id="editProjName"
                                       name="projName"
                                       value={this.state.projName}
                                       onChange={this.handleChangeInput}
                                       onKeyDown={this.onEnter}
                                       onBlur={this.editOnblur}
                                       size={this.state.projName.length+1}
                                       disabled={true}
                                />
                                <span className="edit_proj" onClick={this.editProjName}> <img src={edit} width='16' alt=""/></span></div>
                            </div>
                            <div className="right">
                                <input type="text"
                                       placeholder="Search"
                                       name="searchProject"
                                       value={this.state.searchProject}
                                       onChange={this.searchProject}
                                />
                                <label>
                                    <input type="file" multiple onChange={this.selectImages} className="file_inp"/>
                                    <div className="new_proj">Add Images</div>
                                </label>

                            </div>
                        </div>

                        {this.props.getDocumentations &&
                        < SortableList items={this.state.items}
                            modalOpen={ this.openModal}
                            webid={this.props.match.params.id}
                            axis="xy"
                            useDragHandle={true}
                            useWindowAsScrollContainer={true}
                            onSortEnd={this.onSortEnd}
                            loadImg={this.state.loading}
                            />
                        }




                    </div>
                </div>
                 <div className={this.state.visibleDragArea?"visib-area active":"visib-area" }>Drag and drop some files here</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const getDocumentations= state.getDocumentations
    const getProject= state.getProject
    return {
        getProject,
        getDocumentations

    };
}

const connectedProject = connect(mapStateToProps)(Project);
export {connectedProject as Project};