import React, { Component } from 'react';
import {RouteComponent} from './components/RouteComponent';

class App extends Component {
    componentDidMount(){
        sessionStorage.clear();
    }
    render() {
        return (
            <div className="App">
              <RouteComponent/>
            </div>
        );
    }
}


export default (App);
