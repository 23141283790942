import { userService } from '../services';
import { alertActions } from './';
// import { notificationActions } from './';
import { modalActions } from './';
import { getInfo } from './';
import { history } from '../helpers';
export const userActions = {
    login,
    logout,
    register,
    searchProjects,
    createProject,
    addFolder,
    delFolder,
    editFolder,
    addUser,
    delUser,
    projectAddFolder,
    deleteProject,
    addImages,
    editProjectName,
    deleteImage,
    addImgLink,
    delImgLink,
    editImgLink,
    addComment,
    editComment,
    completeComment,
    addApi,
    editApi,
    delApi,
    sortImg,
    deleteComment,
    removeApiOne,
    replaceImg,
    headerFooter

};
function login(user) {
    return dispatch => {
        dispatch(request());
        userService.login(user)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: 'LOGIN_REQUEST' } }
    function success(user) { return { type: 'LOGIN-SUCCESS', user } }
    function failure(error) { return { type: 'LOGIN-FAILURE', error } }
}
function logout() {
    return dispatch =>{
        dispatch(request());
        userService.logout()
            .then(
                (user)=>{
                    dispatch(success());
                    history.push('/');
                    dispatch(loguot())
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup'));
                }

        );
    }
    function loguot() { return { type: 'SUCCESS-LOGOUT' } }
    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS'} }
    function failure() { return { type: 'FAILURE' } }
};
function register(user) {
    return dispatch => {
        dispatch(request(user));
        userService.register(user)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/home');
                    dispatch(alertActions.success('Registration successful'));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                }
            );
    };

    function request(user) { return { type: 'RIGISTRATION-REQUEST' } }
    function success(user) { return { type: 'RIGISTRATION-SUCCESS', user } }
    function failure(error) { return { type: 'RIGISTRATION-FAILURE', error } }
}
function searchProjects(data, offset) {
    return dispatch => {
        dispatch(request());
        userService.searchProjects('admin/search/websites?key=', data, offset)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Error'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getProjects) { return { type: 'GET_PROJECTS', getProjects } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function createProject(data) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo('admin/websites/website/?key=', data)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                    dispatch(modalActions.close_modal());
                    history.push(`/project/${data.id}`);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'CREATE_PROJECT', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function deleteProject(data1) {
    return dispatch => {

        userService.DeleteInfo(`admin/websites/website/${data1.id}?key=`, data1)
            .then(
                data => {

                    dispatch(success(data1.id));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function success(delProject) { return { type: 'DELETE_PROJECT', delProject } }
}
function deleteImage(data1) {
    return dispatch => {

        userService.DeleteInfo(`admin/websites/documentation/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data1.id));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function success(delImage) { return { type: 'DELETE_IMAGE', delImage } }
}
function addFolder(data) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo('admin/websites/folder/?key=', data)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST1' } }
    function success(addFolder) { return { type: 'ADD_FOLDER', addFolder } }
    function success1() { return { type: 'SUCCESS1' } }
    function failure() { return { type: 'FAILURE1' } }
}
function delFolder(data1) {
    return dispatch => {
        dispatch(request());
        userService.DeleteInfo(`admin/websites/folder/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data1.id));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST1' } }
    function success(delFolder) { return { type: 'DELETE_FOLDER', delFolder } }
    function success1() { return { type: 'SUCCESS1' } }
    function failure() { return { type: 'FAILURE1' } }
}
function editFolder(data1) {
    return dispatch => {
        dispatch(request());
        userService.EditInfo(`admin/websites/folder/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST1' } }
    function success(editFolder) { return { type: 'EDIT_FOLDER', editFolder } }
    function success1() { return { type: 'SUCCESS1' } }
    function failure() { return { type: 'FAILURE1' } }
}
function addUser(data) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo('admin/users/user?key=', data)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Error'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(addUser) { return { type: 'ADD_USER', addUser } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}

function delUser(data1) {
    return dispatch => {
        dispatch(request());
        userService.DeleteUser(`admin/users/user/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data1.id));
                    dispatch(success1());
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST1' } }
    function success(delUser) { return { type: 'DELETE_USER', delUser } }
    function success1() { return { type: 'SUCCESS1' } }
    function failure() { return { type: 'FAILURE1' } }
}
function projectAddFolder(data1) {
    return dispatch => {
        userService.projectAddFolder(`admin/websites/website/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data1));
                },
                error => {
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function success(projAddFold) { return { type: 'PROJECT_ADD_FOLDER', projAddFold } }
}
function addImages(data1,id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            userService.addImages(`admin/websites/documentation/?key=`, data1)
                .then(
                    data => {
                        resolve(dispatch(success(data)));
                        // dispatch(getInfo.getDocumentations(id));
                        dispatch(success1());
                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));
                        dispatch(modalActions.open_modal('Fail_popup', error));
                    }
                );
        })
    };
    function request() { return { type: 'REQUEST' } }
    function success(projAddImg) { return { type: 'PROJECT_ADD_IMAGES', projAddImg } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function replaceImg(data1, id) {
    return dispatch => {
        dispatch(request());
        userService.ReplaceImg(`admin/websites/documentation/${id}?key=`, data1)
            .then(
                data => {
                    // dispatch(success(data));
                    dispatch(getInfo.getDocumentation(id));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    // function success(projReplaceImg) { return { type: 'PROJECT_REPLACE_IMAGES', projReplaceImg } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function sortImg(data1, id) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(`admin/websites/documentation_sort/?key=`, data1)
            .then(
                data => {
                    dispatch(success());
                    dispatch(getInfo.getDocumentations(id))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Error'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function editProjectName(data1) {
    return dispatch => {
        dispatch(request());
        userService.EditInfo(`admin/websites/website/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function addImgLink(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            userService.PostInfo('admin/websites/documentation_link/?key=', data)
                .then(
                    data => {
                        resolve(dispatch(success()));
                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));
                        dispatch(modalActions.open_modal('Fail_popup', error));
                    }
                );
        })
    }
    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}

function delImgLink(data1) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            userService.DeleteInfo(`admin/websites/documentation_link/${data1.id}?key=`, data1)
                .then(
                    data => {
                        resolve(dispatch(success()));
                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));
                        dispatch(modalActions.open_modal('Fail_popup', error));
                    }
                );
        })
    };
    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function editImgLink(data1) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            userService.EditLink(`admin/websites/documentation_link/${data1.id}?key=`, data1)
                .then(
                    data => {
                        resolve(dispatch(success()));
                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));
                        dispatch(modalActions.open_modal('Fail_popup', error));
                    }
                );
        })
    };
    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function addComment(data1) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo('admin/websites/documentation_comment/?key=', data1)
            .then(
                data => {
                    // dispatch(success(data));
                    dispatch(success1());
                    dispatch(getInfo.getComments(data1.documentation_id))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    // function success(addComment) { return { type: 'ADD_COMMENT', addComment } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function editComment(data1) {
    return dispatch => {
        dispatch(request());
        userService.EditLink(`admin/websites/documentation_comment/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST1' } }
    function success(editComment) { return { type: 'EDIT_COMMENT', editComment } }
    function success1() { return { type: 'SUCCESS1' } }
    function failure() { return { type: 'FAILURE1' } }
}
function completeComment(data1) {
    return dispatch => {
        dispatch(request());
        userService.EditLink(`admin/websites/documentation_comment/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(completeComment) { return { type: 'COMPLETE_COMMENT', completeComment } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function deleteComment(data1,screenId) {
    return dispatch => {
        dispatch(request());
        userService.DeleteInfo(`admin/websites/documentation_comment/${data1.id}?key=`, data1)
            .then(
                data => {
                    // dispatch(success(data1.id));
                    dispatch(success1());
                    dispatch(getInfo.getComments(screenId))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    // function success(delComment) { return { type: 'DELETE_COMMENT', delComment } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function addApi(data) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo('admin/websites/documentation_api/?key=', data)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(addApi) { return { type: 'ADD_API', addApi } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function editApi(data1, accId) {
    return dispatch => {
        dispatch(request());
        userService.EditLink(`admin/websites/documentation_api/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(getInfo.getApiTemplates(accId));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(editApi) { return { type: 'EDIT_API', editApi } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function delApi(data1, accId) {
    return dispatch => {
        dispatch(request());
        userService.DeleteInfo(`admin/websites/documentation_api/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success(data1.id));
                    dispatch(getInfo.getApiTemplates(accId));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(delApi) { return { type: 'DELETE_API', delApi } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function removeApiOne(id) {
    return dispatch => {
        dispatch(success(id));

    }
    function success(id) { return { type: 'REMOVE_API_ONE', id } }
}
function headerFooter(data1) {
    return dispatch => {
        dispatch(request());
        userService.EditLink(`admin/websites/documentation/${data1.id}?key=`, data1)
            .then(
                data => {
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}