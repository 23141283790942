import React from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../actions/index';
import logo from '../../img/logo.png';
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {
                username: '',
                password: '',
                login_type: 1
            }

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChangeInput(e) {
        const {name, value} = e.target;
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }


    handleSubmit(event) {
        event.preventDefault();
        const {user} = this.state;
        const {dispatch} = this.props;
        dispatch(userActions.login(user));
    }

    render() {
        const {user} = this.state;
        return (
            <div>
                <div className="login_page">
                    <div className="left_login">

                        <form onSubmit={this.handleSubmit} className="login_form">
                            <div className="login_container">
                                <div className="logo"><img src={logo} alt=""/></div>
                                <h1 className="title1">Reflect</h1>
                                <h3 className="title2">Tie your API to your UI</h3>
                                {this.props.alert &&   <div className="error-mess">Not correct email or password</div>}
                                <label >
                                    <span className="label_txt">Email Address</span>
                                    <input type="text" name="username" value={user.username ? user.username : ''}
                                           onChange={this.handleChangeInput} placeholder="Email Address"/>
                                </label>
                                <label >
                                    <span className="label_txt">Password</span>
                                    <input type="password" name="password" value={user.password ? user.password : ''}
                                           onChange={this.handleChangeInput} placeholder="Enter 6 characters or more"/>
                                </label>
                                <button className="but">Sign in</button>
                            </div>
                        </form>
                    </div>
                    <div className="right_login">

                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert.message
    };
}

const connectedLogin = connect(mapStateToProps)(Login);
export {connectedLogin as Login};