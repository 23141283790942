/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
class AddUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            user:{
                firstname:'',
                lastname:'',
                phone:'',
                email:''
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(userActions.addUser(this.state.user));
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleChangeInput(e) {
        const {name, value} = e.target;
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    render() {
        const {user} = this.state;
        return (
            <div  className="modal_content">
                <div className="modal_head">
                   Add User
                </div>
                <div className="modal_bod">
                    <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                            <div className="login_container">
                                {this.props.alert &&   <div className="error-mess1">{this.props.alert.error && this.props.alert.error.message?this.props.alert.error.message:'Error'}</div>}
                                <label >
                                    <span className="label_txt">Firstname</span>
                                    <input type="text" name="firstname" value={user.firstname ? user.firstname : ''}
                                           onChange={this.handleChangeInput} placeholder="Firstname"/>
                                </label>
                                <label >
                                    <span className="label_txt">Lastname</span>
                                    <input type="text" name="lastname" value={user.lastname ? user.lastname : ''}
                                           onChange={this.handleChangeInput} placeholder="lastname"/>
                                </label>
                                <label >
                                    <span className="label_txt">Phone</span>
                                    <input type="text" name="phone" value={user.phone ? user.phone : ''}
                                           onChange={this.handleChangeInput} placeholder="Phone"/>
                                </label>
                                <label >
                                    <span className="label_txt">Email</span>
                                    <input type="text" name="email" value={user.email ? user.email : ''}
                                           onChange={this.handleChangeInput} placeholder="Email"/>
                                </label>
                            </div>

                        <div className="but_cont two_but">
                            <button type="submit" className="new_proj">Add</button>
                            <button type="submit" className="new_proj cancel_pr" onClick={()=>this.close_modal()}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals,
        alert: state.alert.message
    };
}

export default connect(mapStateToProps)(AddUser);
