export function GetData(state = {}, action) {
    switch (action.type) {
        case 'GET_DATA':
            return {
                data: action.data,
            }

        default:
            return state
    }
}

export function createProject(state = {}, action) {
    switch (action.type) {
        case 'CREATE_PROJECT':
            return {
                data: action.data,
            }

        default:
            return state
    }
}
export function getProject(state = {}, action) {
    switch (action.type) {
        case 'GET_PROJECT':
            return {
                getProject: action.getProject,
            }

        default:
            return state
    }
}
export function getDocumentation(state = {}, action) {
    switch (action.type) {
        case 'GET_DOCUMENTATION':
            return {
                getDocumentation: action.getDocumentation,
            }

        default:
            return state
    }
}
export function getDocumentations(state = {}, action) {
    switch (action.type) {
        case 'GET_DOCUMENTATIONS':
            return {
                getDocumentations: action.getDocumentations,
            }
        case 'DELETE_IMAGE':
            let getDocumentations = state.getDocumentations.filter((item) => item.id !== action.delImage);
            return{
                getDocumentations,
            }
        case 'PROJECT_ADD_IMAGES':
            // console.log(state.getDocumentations.push(action.projAddImg))
            getDocumentations = [...state.getDocumentations, action.projAddImg]
            return {
                getDocumentations
            }
        default:
            return state
    }
}
// export function projectAddFolder(state = {}, action) {
//     switch (action.type) {
//         case 'PROJECT_ADD_IMAGES':
//             return {
//                 projAddImg: action.projAddImg,
//             }
//
//         default:
//             return state
//     }
// }
export function getProjects(state = {}, action) {
    switch (action.type) {
        case 'GET_PROJECTS':
            return {
                getProjects: action.getProjects,
            }
        case 'PROJECT_ADD_FOLDER':
            state.getProjects = state.getProjects.map((item) => {
                if (item.id === action.projAddFold.id) {
                    return {...item, folder_id: action.projAddFold.folder_id}
                } else {
                    return item;
                }
            });

            return {
                getProjects: state.getProjects
            };
        case 'DELETE_PROJECT':
            let getProjects = state.getProjects.filter((item) => item.id !== action.delProject);
            return{
                getProjects,
            }
        default:
            return state
    }
}
export function getFolders(state = {}, action) {
    switch (action.type) {
        case 'GET_FOLDERS':
            return {
                getFolders: action.getFolders,
            }
        case 'ADD_FOLDER':
            let getFolders = [...state.getFolders, action.addFolder]
            return{
                getFolders
            }
        case 'DELETE_FOLDER':
            getFolders = state.getFolders.filter((item) => item.id !== action.delFolder);
            return{
                getFolders,
            }
        case 'EDIT_FOLDER':
            return{
                ...state,
                getFolders: state.getFolders.map(item=>
                    item.id === action.editFolder.id
                        ? action.editFolder
                        : item
                )
            }
        default:
            return state
    }
}
export function getUsers(state = {}, action) {
    switch (action.type) {
        case 'GET_USERS':
            return {
                getUsers: action.getUsers,
            }
        case 'ADD_USER':
            let getUsers = [...state.getUsers, action.addUser]
            return{
                getUsers
            }
        case 'DELETE_USER':
            getUsers = state.getUsers.filter((item) => item.id !== action.delUser);
            return{
                getUsers,
            }
        default:
            return state
    }
}
export function getImgLinks(state = {}, action) {
    switch (action.type) {
        case 'GET_IMAGE_LINKS':
            return {
                getImgLinks: action.getImgLinks,
            }

        default:
            return state
    }
}
export function getComments(state = {}, action) {
    switch (action.type) {
        case 'GET_COMMENTS':
            return {
                getComments: action.getComments,
            }
            // case 'ADD_COMMENT':
            //     let getComments;
            //     if(action.addComment.parent_id){
            //        getComments =  state.getComments.map(item=>{
            //             if(item.id === action.addComment.parent_id){
            //                return  {...item, replies:[...item.replies, action.addComment]}
            //             }
            //             else return item
            //         })
            //     }
            //     else getComments = [...state.getComments, action.addComment]
            // return {
            //     getComments
            // }
        // case 'DELETE_COMMENT':
        //     getComments = state.getComments.filter((item) => item.id != action.delApi);
        //     return {
        //         getApi
        //     }
        case 'EDIT_COMMENT':
            let getComments =  state.getComments.map(item=>{
                    if(item.id === action.editComment.id){
                        return  {...item, x:action.editComment.x, y: action.editComment.y}
                    }
                    else return item
            })


            return {
                getComments
            }
        case 'COMPLETE_COMMENT':
            getComments =  state.getComments.map(item=>{
                if(item.id === action.completeComment.id){
                    return  {...item, completed:"1"}
                }
                else return item
            })


            return {
                getComments
            }
        default:
            return state
    }
}
export function getApi(state = {}, action) {
    switch (action.type) {
        case 'GET_API':
            return {
                getApi: action.getApi,
            }
        case 'ADD_API':
            let getApi;
            getApi = [...state.getApi, action.addApi]
            return {
                getApi
            }
        case 'EDIT_API':
             getApi =  state.getApi.map(item=>{
                if(item.id === action.editApi.id){
                     return  action.editApi
                }
                else return item
            })
            return {
                getApi
            }
        case 'GET_API_ONE':
            getApi =  state.getApi.map(item=>{
            if(item.id === action.id.id){
                return  {...item, authorization:action.getApiOne.authorization, body:action.getApiOne.body,
                        description:action.getApiOne.description,
                        headers:action.getApiOne.headers,
                        method:action.getApiOne.method,
                        params:action.getApiOne.params,
                        response:action.getApiOne.response,
                        url:action.getApiOne.url,
                        }
            }
            else return item
            })
            return {
                getApi
            }
        case 'REMOVE_API_ONE':
            console.log(action.id)
            getApi =  state.getApi.map(item=>{
                if(item.id === action.id){
                    return  {...item, authorization:'',
                        description:'',
                        headers:'',
                        method:'',
                        params:'',
                        response:'',
                        url:'',
                    }
                }
                else return item
            })
        return {
            getApi
        }
        case 'DELETE_API':
            getApi = state.getApi.filter((item) => item.id !== action.delApi);
            return {
                getApi
            }
        default:
            return state
    }
}
export function getApiTemplates(state = {}, action) {
    switch (action.type) {
        case 'API_TEMPLATES':
            return {
                getApiTemplates: action.getApiTemplates,
            }

        default:
            return state
    }
}