import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { loadingBarMiddleware } from 'react-redux-loading-bar'

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loadingBarMiddleware({
            scope: 'sectionBar',
            promiseTypeSuffixes: ['REQUEST1', 'SUCCESS1', 'FAILURE1'],
        }),
        loadingBarMiddleware({
            promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
        }),
        loggerMiddleware
    )
);