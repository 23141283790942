/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
class DeleteUser extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            id: EditContent
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(userActions.delUser({id:this.state.id}));
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    render() {

        return (
            <div  className="modal_content">
                <div className="modal_head">
                   Delete  this user?
                </div>
                <div className="modal_bod">
                    <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                        <div className="but_cont two_but">
                            <button type="submit" className="new_proj">Delete</button>
                            <button type="submit" className="new_proj cancel_pr" onClick={()=>this.close_modal()}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(DeleteUser);
