import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {userActions} from '../../actions/index';
import {getInfo} from '../../actions/index';
import moment from 'moment';
import $ from "jquery";
import { Scrollbars } from 'react-custom-scrollbars';
import {Rnd} from 'react-rnd';
class Comments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            addCommentMain:{
                name: '',

            },
            scale:1,
            footer:0,
            leftContainer:450

        }
        this.handleChangeMain = this.handleChangeMain.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentWillReceiveProps(nextProps){
        let indElement=0;
        if(nextProps.getDocumentation && nextProps.getComments ){
            this.setState(state => {
                const comments = nextProps.getComments.map((item1) => {
                    if (item1.x !== null && item1.x !== undefined) {
                        indElement++;
                        return {...item1, bind_id:indElement}
                    } else {
                        return item1;
                    }
                });

                return {
                    comments
                };
            }, function () {
                this.loadEnd()
            });
        }
    }
    // component
    componentDidMount() {
        let leftContainer = localStorage.getItem('leftContainer')
        if(leftContainer){
            this.setState({
                leftContainer
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getComments(this.props.screenId))
        dispatch(getInfo.getDocumentation(this.props.screenId));
    }
    componentDidUpdate(nextProps, nextState){
        const { dispatch } = this.props;
        if( nextProps.screenId !== this.props.screenId){
            dispatch(getInfo.getComments(this.props.screenId))
            dispatch(getInfo.getDocumentation(this.props.screenId));
        }
    }
    handleChangeMain(e) {
        const { name, value } = e.target;
        const {addCommentMain} = this.state
        this.setState({
            addCommentMain:{
                ...addCommentMain,
                [name]: value
            }

        });

    }
    handleSubmit(event){
        event.preventDefault();
        const { dispatch } = this.props;
        let sendObj;
        sendObj={
            name:this.state.addCommentMain.name,
            website_id:this.props.projId,
            documentation_id:this.props.screenId
        }


        dispatch(userActions.addComment(sendObj))
        this.setState({
            addCommentMain:{
                name:''
            }
        })

    }
    loadEnd = (e)=>{
        let width =$('.main_img').get(0).naturalWidth;
        let height =$('.main_img').get(0).naturalHeight;
        let widthNow=$('.prev_img1').width()
        let heightNow=$('.prev_img1').height()
        let heightContainer = $('.scrollBar').height();
        let scale=widthNow/width;
        this.setState({scale:scale})
        if(heightNow>heightContainer){
            let footer = (height - this.props.getDocumentation.footer)*scale
            this.setState({scroll: 'yes', footer:footer})
        }
        else{
            this.setState({scroll: 'no'})
        }

    }
    outOpenEdit =(e, id) =>{

        let target1 = $(`.comment_list  .comment[data-id = ${id}]`).offset().top;
        let target2 = $(`.comment_list`).offset().top;
        let target3 = $(`.comment_list`).scrollTop();
        let position = target3 + target1 - target2 - 18;
        $('.comment_list').animate({scrollTop:position}, 1000)
    }
    render() {
        return (
            <div id="comment_main_container" className="screen_cont_inside">
                <div className="scr_name">{this.props.getDocumentation && this.props.getDocumentation.name}</div>
                <div className="img-container1">
                    {this.props.getDocumentation && this.state.comments &&
                    <div className="device_container iphonex" >

                        {this.state.scroll==='yes' && this.props.getDocumentation.header &&
                        <div className="header_prev"
                             style={{
                                 background: `url(https://imsba.com/${this.props.getDocumentation.img})`,
                                 backgroundRepeat: 'no-repeat',
                                 height: `${this.props.getDocumentation.header*this.state.scale}px`,
                                 width: '330px',
                                 backgroundPosition: 'top center',
                                 marginLeft: '22px',
                                 backgroundSize:'100%',

                             }}
                        >
                        </div>
                        }
                        {this.state.scroll==='yes' && this.props.getDocumentation.footer &&
                        <div className="footer_prev"
                             style={{
                                 background: `url(https://imsba.com/${this.props.getDocumentation.img})`,
                                 backgroundRepeat: 'no-repeat',
                                 height: `${this.state.footer}px`,
                                 width: '330px',
                                 backgroundPosition: 'bottom center',
                                 marginLeft: '22px',
                                 backgroundSize:'100%',

                             }}
                        >

                        </div>
                        }
                        <Scrollbars
                            style={{ width: 330, height: 660, marginLeft: 22 }}
                            className="scrollBar"
                            renderView={props => <div {...props}  className="view_prev"/>}
                            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                            autoHide
                        >
                            <div className="center_cont">
                                <img src={`https://imsba.com/${this.props.getDocumentation.img}`} alt="" className="main_img prev_img1"/>

                                {this.state.comments.map((item, index) =>{
                                        if(item.x !== null && item.x !== undefined){

                                            return  <div className="onComment mod_preview"
                                                         style={{width: `0`, height:`0`,left:`${item.x*this.state.scale  }px`,top:`${item.y*this.state.scale  }px`,}}
                                                         id={item.id} key={item.id} onClick={ e => this.outOpenEdit(e, item.id)}>{item.bind_id}</div>

                                        }
                                        else return false
                                    }

                                    )

                                }
                            </div>
                        </Scrollbars>

                    </div>
                    }
                </div>
                <Rnd
                    size={{width: this.state.leftContainer}}
                    // position={{x: 0, y:0}}
                    minWidth="400"
                    bounds="body"
                    className="dragLeft"
                    enableResizing={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                    disableDragging={true}
                    onResize={(e, direction, ref, delta, position) => {
                        this.setState({
                            leftContainer: ref.style.width,
                            ...position,
                        });
                        localStorage.setItem('leftContainer',ref.style.width)
                        this.props.leftContainer(ref.style.width)
                    }}
                >
                <div className="comments_container">
                    <div className="top">
                        <div className="name_screen">Intro Screen</div>
                        <Link to={`?prototype`} className="close_thumb flex-center" data-id="prototype"
                        >Close</Link>
                    </div>
                    <div className="comment_list">
                        {this.state.comments && this.state.comments.map( item=>{
                                if(item.id!=='tempid1'){
                                    return <div className="comment" id={item.id} key={item.id} data-id={item.id}>
                                        <div className={item.completed === "1" ? "comment_incl completed": "comment_incl"}>
                                            <div className="left">{item.user?item.user.firstname.substring(0, 1):''}{item.user?item.user.lastname.substring(0, 1):''}</div>
                                            <div className="right">
                                                <div className="main_comment">
                                                    <div className="name_sender">{item.user? item.user.firstname: ''} {item.user? item.user.lastname:''}</div>
                                                    <div className="mess_txt">{item.name}</div>
                                                    {/*{item.x !== null && item.x !== undefined &&  this.state.comments.map((item2, index)=>{*/}
                                                        {/*if(item2.x){*/}
                                                            {/*return <div className="bind_comment" key={index+100}>{item.bind_id}</div>*/}
                                                        {/*}*/}
                                                        {/*else return false*/}
                                                    {/*})*/}
                                                    {/*}*/}
                                                    {item.x &&( item.x != null || item.x != undefined) ?
                                                        <div className="bind_comment"  key={item.bind_id + 100}>{item.bind_id}</div>:''}
                                                </div>
                                                <div className="comment_bot">
                                                    <div className="date">
                                                        {item.datetime && <span>{moment(new Date(item.datetime* 1000)).format('LL HH:mm')}</span>}
                                                    </div>
                                                    <div className="comment_action">
                                                        {/*<div className="complete" onClick={(e)=> this.completeComment(e, item)}>Complete</div>*/}
                                                        <div className="complete1" >Completed</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {item.replies && item.replies.map( item1=>
                                            <div className="comment" id={item1.id} key={item1.id}>
                                                <div className="comment_incl">

                                                    <div className="left">{item1.user ?item1.user.firstname.substring(0, 1):''}{item1.user ?item1.user.lastname.substring(0, 1):''}</div>
                                                    <div className="right">
                                                        <div className="main_comment">
                                                            {/*<div className="name_sender">{item1.sender_name}</div>*/}
                                                            <div className="name_sender">{item1.user?item1.user.firstname:''} {item1.user?item1.user.lastname:''}</div>
                                                            <div className="mess_txt">{item1.name}</div>
                                                        </div>
                                                        <div className="comment_bot">

                                                            <div className="date">
                                                                {item1.datetime && <span>{moment(new Date(item1.datetime* 1000)).format('LL HH:mm')}</span>}
                                                            </div>
                                                            <div className="comment_action">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                                else return false
                            }
                        )}



                    </div>
                    {
                        this.props.authorized ?
                            <form className="form_container" onSubmit={this.handleSubmit}>
                                <div className="send_top">
                                    <textarea placeholder="Write comments or @mentions" name="name"
                                              value={this.state.addCommentMain.name}
                                              onChange={this.handleChangeMain}></textarea>
                                    <button className="close_thumb flex-center">Send</button>
                                </div>
                            </form> : null
                    }

                </div>
                </Rnd>


            </div>
        );
    }
}

function mapStateToProps(state) {
    const {getComments}=state.getComments
    const {getDocumentation}=state.getDocumentation
    return {
        getComments,
        getDocumentation
    };
}

const connectedComments = connect(mapStateToProps)(Comments);
export {connectedComments as Comments};
