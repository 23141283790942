
import React, { Component } from "react";
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import {  Modal, ModalBody } from 'reactstrap';
import { alertActions } from '../../actions/index';
import  Empty  from './empty.js';
import  Success  from './success.js';
import  Fail_popup  from './fail_poup.js';
// import  Login  from './createProject.js';
// import  Registration  from './registration.js';
import  DeleteItem  from './deleteItem.js';
import  CreateProject  from './createProject.js';
import  DeleteImage  from './deleteImage.js';
import  AddUser  from './addUser.js';
import  DeleteUser  from './deleteUser.js';
import close from '../../img/close.svg';


class Popup extends Component {
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    render() {
        const components = {
            'Empty': Empty,
            'Success': Success,
            'Fail_popup':Fail_popup,
            // 'Registration':Registration,
            'CreateProject':CreateProject,
            'DeleteItem':DeleteItem,
            'DeleteImage':DeleteImage,
            'AddUser':AddUser,
            'DeleteUser':DeleteUser,

        };
       let FuncMod =  components[this.props.modals.modal_name || 'Empty' ];
        return (

            <div>
                <Modal isOpen={this.props.modals.open_modal} toggle={()=>this.close_modal()} className='modal-dialog-centered'>
                    <button type="button" className="close_mod" onClick={()=>this.close_modal()}><img src={close} width='16' alt=""/></button>
                    <ModalBody >
                          <FuncMod />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        modals: state.modals
    };
}

const connectedPopup = connect(mapStateToProps)(Popup);
export { connectedPopup as Popup};


