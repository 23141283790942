import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {getInfo} from '../../actions/index';
import $ from "jquery";
import { Scrollbars } from 'react-custom-scrollbars';
import {Rnd} from 'react-rnd';
class ApiItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tool: '',
            params: this.props.item.params ? JSON.parse(this.props.item.params) : [],
            authorization: this.props.item.authorization ? JSON.parse(this.props.item.authorization) : [],
            headers: this.props.item.headers ? JSON.parse(this.props.item.headers) : [],
            body: this.props.item.body ? JSON.parse(this.props.item.body) : [],
            response: this.props.item.response ? this.props.item.response : '',
            description: this.props.item.description ? this.props.item.description : '',
        }

    }

    CopyText = (e, text) => {
        let copyId= $(e.currentTarget).attr('data-id')
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.setState({
            tool: copyId
        });
        const _this = this;
        setTimeout(function () {
            _this.setState({
                tool: ''
            });
        }, 700)

    }
    tabClick =(e)=>{
        const self=$(e.currentTarget)
        if(!self.hasClass('active')){
            const dataid=self.attr('data-id')
            $('.tabItem').removeClass('active')
            $(`.tab_body`).removeClass('active')
            self.addClass('active')
            $(`.tab_body${dataid}`).addClass('active')
        }
    }
    componentDidMount(){
        var pre = document.getElementsByTagName('pre'),
            pl = pre.length;
        for (var i = 0; i < pl; i++) {
            pre[i].innerHTML = '<span class="line-number"></span>' + pre[i].innerHTML + '<span class="cl"></span>';
            var num = pre[i].innerHTML.split(/\n/).length;
            for (var j = 0; j < num; j++) {
                var line_num = pre[i].getElementsByTagName('span')[0];
                line_num.innerHTML += '<span>' + (j + 1) + '</span>';
            }
        }
        var str = $('.api_prev_url').html()
        const newStr = str.replace(/({{)/g, "<span style='color: #edaa17'> $1").replace(/(}})/g, '$1 </span> ')
        $('.api_prev_url').html(newStr)
        $('.bot_api').find('.prev_table').each(function (item) {
           var content =  $(this).html();
           const newStr1 =content.replace(/({{)/g, "<span style='color: #edaa17'> $1").replace(/(}})/g, '$1 </span> ')
           $(this).html(newStr1)
        })
    }
    render() {
        return (
            <div className="bot_api">
                {/*<div className="api_txt">*/}
                    {/*<p>Welcome to IMSBA. The complete solution for any application you might need.</p>*/}
                    {/*<p>With our platform you will be able to create applications such as:</p>*/}
                    {/*<p>Blogs, E-Commerce, Games, Blockchain Solutions, Chat / Messangers, Transportation & Rideshare,*/}
                        {/*Gig Economy, Sharing Economy, Directories, Websites, & More.</p>*/}
                {/*</div>*/}
                <div className="link_method">
                    <div className="left_meth">
                        <span className="method_api">{this.props.item.method ? this.props.item.method : "GET"}</span>
                        <span className="api_prev_url">{this.props.item.url ? this.props.item.url : ''}</span>
                    </div>
                    <div className="right_meth" data-id='tool1' onClick={e => this.CopyText(e, this.props.item.url)}>
                        <span>COPY</span>
                        {this.state.tool === 'tool1' ? <div className="tool">Copied</div> : ''}
                    </div>
                </div>
                <div className="api_txt">
                    <p>{this.state.description ?this.state.description:'' }</p>
                </div>
                <div className="tab_head prev_head_tab">
                    <div className="tabItem active" data-id="#params" onClick={this.tabClick}>Url params </div>
                    <div className="tabItem" data-id="#authorization" onClick={this.tabClick}>Authorization</div>
                    <div className="tabItem" data-id="#headers" onClick={this.tabClick}>Headers</div>

                    <div className="tabItem" data-id="#body" onClick={this.tabClick}>Body </div>
                </div>

                <div className="tab_body active" id="params">
                    <table className="prev_table">
                        <tbody>
                        {this.state.params && this.state.params.map((item, index)=>
                            <tr key={index}>
                                <td>{item.key?item.key:''}</td>
                                <td>
                                    <div>{item.value?item.value:''}</div>
                                    <div className="api_desc">{item.description?item.description:''}</div>
                                </td>
                            </tr>
                        )}


                        </tbody>
                    </table>
                </div>
                <div className="tab_body" id="authorization">
                    <table className="prev_table">
                        <tbody>
                        {this.state.authorization && this.state.authorization.map((item, index)=>
                            <tr key={index}>
                                <td>{item.key?item.key:''}</td>
                                <td>
                                    <div>{item.value?item.value:''}</div>
                                    <div className="api_desc">{item.description?item.description:''}</div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="tab_body" id="headers">
                    <table className="prev_table">
                        <tbody>
                        {this.state.headers && this.state.headers.map((item, index)=>
                            <tr key={index}>
                                <td>{item.key?item.key:''}</td>
                                <td>
                                    <div>{item.value?item.value:''}</div>
                                    <div className="api_desc">{item.description?item.description:''}</div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="tab_body" id="body">
                    <table className="prev_table">
                        <tbody>
                        {this.state.body && this.state.body.map((item, index)=>
                            <tr key={index}>
                                <td>{item.key?item.key:''}</td>
                                <td>
                                    <div>{item.value?item.value:''}</div>
                                    <div className="api_desc">{item.description?item.description:''}</div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>


                <div className="prev_table_head bold_api">SAMPLE RESPONSE</div>
                <div className="relative_block">
                {/*<div className="tab_body active" id="body">*/}
                    <pre className="resp_txt">
                       <code>{this.state.response?this.state.response:''}</code>
                    </pre>
                    <div className="right_meth copy_resp" data-id="tool2" onClick={e => this.CopyText(e, this.state.response)}>
                        <span>COPY</span>
                        {this.state.tool === 'tool2'  ? <div className="tool">Copied</div> : ''}
                    </div>

                {/*</div>*/}
                </div>


            </div>
        )
    }
}

class Api extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            api: [],
            editApi: '',
            scale:1,
            footer:0,
            leftContainer:450

        }


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.getDocumentation && nextProps.getApi) {
            this.setState({
                api: nextProps.getApi
            }, function () {
                this.loadEnd()
            });
        }
    }
    componentDidUpdate(nextProps, nextState){
        const { dispatch } = this.props;
        if( nextProps.screenId !== this.props.screenId){
            dispatch(getInfo.getApi(this.props.screenId))
            dispatch(getInfo.getDocumentation(this.props.screenId));
        }
    }

    componentDidMount() {
        let leftContainer = localStorage.getItem('leftContainer')
        if(leftContainer){
            this.setState({
                leftContainer
            })
        }
        const {dispatch} = this.props;
        dispatch(getInfo.getApi(this.props.screenId))
        dispatch(getInfo.getDocumentation(this.props.screenId));
    }

    editApi = (e, id) => {
        const self = $(e.currentTarget)
        if (!self.hasClass('active')) {
            $('.but_edit').removeClass('active')
            self.addClass('active')
            this.setState({
                editApi: id
            });
        }
        else {
            self.removeClass('active')
            this.setState({
                editApi: ''
            });
        }
    }
    loadEnd = (e)=>{
        let width =$('.main_img').get(0).naturalWidth;
        let height =$('.main_img').get(0).naturalHeight;
        let widthNow=$('.prev_img1').width()
        let heightNow=$('.prev_img1').height()
        let heightContainer = $('.scrollBar').height();
        let scale=widthNow/width;
        this.setState({scale:scale})
        if(heightNow>heightContainer){
            let footer = (height - this.props.getDocumentation.footer)*scale
            this.setState({scroll: 'yes', footer:footer})
        }
        else{
            this.setState({scroll: 'no'})
        }

    }
    outOpenEdit =(e, id) =>{
        e.preventDefault();
        e.stopPropagation();
        // e.stopImmediatePropagation();
        $('.but_edit').removeClass('active')
        $(`.comment_list  .api[data-open-id = ${id}] .but_edit`).addClass('active')

        this.setState({
            editApi: id
        }, function () {
            let target1 = $(`.comment_list  .api[data-open-id = ${id}]`).offset().top;
            let target2 = $(`.comment_list`).offset().top;
            let target3 = $(`.comment_list`).scrollTop();
            let position = target3 + target1 - target2;
            $('.comment_list').animate({scrollTop:position}, 1000)
        });

    }
    render() {
        return (
            <div id="comment_main_container" className="screen_cont_inside">
                <div className="scr_name">{this.props.getDocumentation && this.props.getDocumentation.name}</div>
                <div className="img-container1">
                    {this.props.getDocumentation && this.state.api &&
                    <div className="device_container iphonex" >
                        {this.state.scroll==='yes' && this.props.getDocumentation.header &&
                        <div className="header_prev"
                             style={{
                                 background: `url(https://imsba.com/${this.props.getDocumentation.img})`,
                                 backgroundRepeat: 'no-repeat',
                                 height: `${this.props.getDocumentation.header*this.state.scale}px`,
                                 width: '330px',
                                 backgroundPosition: 'top center',
                                 marginLeft: '22px',
                                 backgroundSize:'100%'

                             }}
                        >
                        </div>
                        }
                        {this.state.scroll==='yes' && this.props.getDocumentation.footer &&
                        <div className="footer_prev"
                             style={{
                                 background: `url(https://imsba.com/${this.props.getDocumentation.img})`,
                                 backgroundRepeat: 'no-repeat',
                                 height: `${this.state.footer}px`,
                                 width: '330px',
                                 backgroundPosition: 'bottom center',
                                 marginLeft: '22px',
                                 backgroundSize:'100%'

                             }}
                        >

                        </div>
                        }
                        <Scrollbars
                            style={{ width: 330, height: 660, marginLeft: 22 }}
                            className="scrollBar"
                            renderView={props => <div {...props}  className="view_prev"/>}
                            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                            autoHide
                        >
                            <div className="center_cont">
                                <img src={`https://imsba.com/${this.props.getDocumentation.img}`} alt="" className="main_img prev_img1"/>

                                { this.state.api.map((item, index) => {
                                        if (item.x !== null && item.x !== undefined) {
                                            return <div key={item.id} className="onComment mod_preview" style={{
                                                width: `0`,
                                                height: `0`,
                                                left: `${item.x*this.state.scale  }px`,
                                                top:`${item.y*this.state.scale  }px`,
                                            }} id={item.id}
                                            onClick={ e => this.outOpenEdit(e, item.id)}>{index + 1}</div>
                                        }
                                        else return false
                                    })
                                    }
                            </div>
                        </Scrollbars>
                    </div>
                    }
                </div>


                <Rnd
                    size={{width: this.state.leftContainer}}
                    // position={{x: 0, y:0}}
                    minWidth="400"
                    bounds="body"
                    className="dragLeft"
                    enableResizing={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                    disableDragging={true}
                    onResize={(e, direction, ref, delta, position) => {
                        this.setState({
                            leftContainer: ref.style.width,
                            ...position,
                        });
                        localStorage.setItem('leftContainer',ref.style.width)
                        this.props.leftContainer(ref.style.width)
                    }}
                >

                <div className="comments_container">
                    <div className="top">
                        <div className="name_screen">Intro Screen</div>
                        <Link to={`?prototype`} className="close_thumb flex-center" data-id="prototype"
                        >Close</Link>
                    </div>
                    <div className="comment_list mod_preview">
                        {this.state.api && this.state.api.map((item, index) => {
                                if (item.id !== 'tempid1') {
                                    return <div className="api" key={item.id} data-open-id={item.id}>

                                        <div className="top_api">
                                            <div className="top_left_api">
                                                <div className="api_number">{index + 1}</div>
                                                <div className="api_name">{item.name}</div>
                                            </div>
                                            <div className="top_right_api">
                                                <div className="edit_api but_edit prev_api"
                                                     onClick={(e) => this.editApi(e, item.id)}>
                                                    <span className="edit_span">View</span>
                                                    <span className="close_span">Close</span>
                                                </div>

                                            </div>
                                        </div>


                                        {this.state.editApi === item.id &&
                                        <ApiItem item={item} dispatch={this.props.dispatch}/>}

                                    </div>
                                }
                                else return false
                            }
                        )}


                    </div>

                </div>
                </Rnd>


            </div>
        );
    }
}

function mapStateToProps(state) {
    const {getApi} = state.getApi
    const {getDocumentation} = state.getDocumentation
    const {getApiTemplates} = state.getApiTemplates
    return {
        getApi,
        getDocumentation,
        getApiTemplates
    };
}

const connectedApi = connect(mapStateToProps)(Api);
export {connectedApi as Api};