import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import $ from "jquery";
import moment from 'moment';
import {history} from '../../helpers';
import Pagination from "react-js-pagination";

import {modalActions} from '../../actions';
import {userActions} from '../../actions';
import {getInfo} from '../../actions';
import LoadingBar from 'react-redux-loading-bar'

import edit from '../../img/edit.svg';
import del from '../../img/delete-button.svg';
import add from '../../img/add.svg';
import logo from '../../img/logo.png';
import img_new_prog from '../../img/gr3.svg';
import menu from '../../img/menu.svg';
import work from '../../img/work.jpg';
import del_white from '../../img/delete-button_white.svg';
import play from '../../img/play-button.svg';
import folder from '../../img/folder.svg';
import checked from '../../img/checked.svg';

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchProject: '',
            actionNav: {
                addfolder: false,
            },
            folder: {
                name: ''
            },
            activePage: 1,
            editFolderName: '',
            editFolderPosition: '',
            projectList: [],
        }


        this.openModal = this.openModal.bind(this)
        this.handleChangeInput1 = this.handleChangeInput1.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeInput3 = this.handleChangeInput3.bind(this)
        this.searchProject = this.searchProject.bind(this)
        this.logout = this.logout.bind(this)
        this.hashHistoryListener = history.listen((location, action) => {
            if (history.location.pathname === "/dashboard") {
                let link = history.location.search;
                let link2 = link.replace('?', '');
                this.upd(link2)
            }
        })


    }

    componentWillUnmount() {
        this.hashHistoryListener();
    }

    componentDidMount() {

        const {dispatch} = this.props;
        // dispatch(getInfo.getProjects({offset:0}))
        dispatch(getInfo.getFolders())
        dispatch(getInfo.getUsers())
        if (history.location.pathname === "/dashboard") {
            let link = history.location.search;
            let link2 = link.replace('?', '');
            this.upd(link2)
        }
        // open close menu
        $('.menu_but').click(function () {
            if (!$(this).hasClass('open_menu')) {
                $('.main_dashboard').addClass('open_navigation_dashboard')
                $(this).addClass('open_menu')
                $('.navigation').addClass('open_navigation')
            }
            else {
                $('.main_dashboard').removeClass('open_navigation_dashboard')
                $(this).removeClass('open_menu')
                $('.navigation').removeClass('open_navigation')
            }
        })
        //profile open close
        $('.men_but_prof').click(function () {
            $(this).next().slideToggle()
        })
    }

    // folder change manual
    upd = (link2) => {
        const {dispatch} = this.props;
        if (link2) {
            this.setState({
                activePage:1
            })
            dispatch(userActions.searchProjects({folder_id: link2}, 0))
        }
        else {
            dispatch(getInfo.getProjects({offset: 0}))
        }
    }

    handlePageChange(pageNumber) {
        const {dispatch} = this.props;
        this.setState({
            activePage: pageNumber
        }, function () {
            let offset=(this.state.activePage - 1)*10;
            if(history.location.search){
                    let link = history.location.search;
                    let link2 = link.replace('?', '');

                    dispatch(userActions.searchProjects({folder_id:link2}, offset));
            }
            else if(this.state.searchProject){
                dispatch(userActions.searchProjects({search:this.state.searchProject}, offset));
            }
            else {
                dispatch(getInfo.getProjects({offset: offset}));
            }
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.getProjects) {
            this.setState({
                projectList: nextProps.getProjects,
            })
        }
    }

    searchProject(e) {
        const {name, value} = e.target;
        // history.push('/dashboard')
        if (history.location.search) {
            history.push('/dashboard')
        }
        this.setState({
            [name]: value,
            activePage:1
        });
        const {dispatch} = this.props;
        setTimeout(function () {
            dispatch(userActions.searchProjects({search:value}, 0));
        }, 500)


    }

    handleChangeInput1(e) {
        const {name, value} = e.target;
        this.setState({
            folder: {
                [name]: value
            }

        });
    }
    handleChangeInput3(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value

        });
    }
    logout() {
        const {dispatch} = this.props;
        dispatch(userActions.logout())
    }

    // open close project folder
    selectFolder = (e) => {
        const self = $(e.currentTarget)
        if (!self.next().hasClass('bounceIn')) {
            self.next().removeClass(' bounceOut ')
            self.next().addClass('bounceIn ')
        }
        else {
            self.next().removeClass(' bounceIn ')
            self.next().addClass('bounceOut ')
        }
    }
    addAction = (e) => {
        this.setState({
            actionNav: {
                addfolder: true,
            }

        }, function () {
            $('#addfolder').focus()
        })

    }
    onEnter = (e) => {
        if (e.key === 'Enter') {
            const {dispatch} = this.props;
            dispatch(userActions.addFolder(this.state.folder))
            this.setState({
                actionNav: {
                    addfolder: false,
                },
                folder: {
                    name: ''
                }
            })
        }
    }
    addFolderCheck =() =>{
        const {dispatch} = this.props;
        dispatch(userActions.addFolder(this.state.folder))
        this.setState({
            actionNav: {
                addfolder: false,
            },
            folder: {
                name: ''
            }
        })
    }
    delFolder = (e) => {
        const folderId = e.currentTarget.getAttribute('data-del-id');
        const {dispatch} = this.props;
        dispatch(userActions.delFolder({id: folderId}))
    }
    editFolder = (e, name) => {
        const folderId = e.currentTarget.getAttribute('data-edit-id');
        $('.group_item').removeClass('edit_folder')
        const self = $(e.currentTarget)
        self.parents('.group_item').addClass('edit_folder')
        this.setState({
            editFolderName: name,
            editFolderPosition: folderId
        }, function () {
            self.parents('.group1').next().next().focus()
        })
    }
    onEditEnter = (e) => {
        if (e.key === 'Enter') {
            $('.group_item').removeClass('edit_folder')
            const {dispatch} = this.props;
            dispatch(userActions.editFolder({id: this.state.editFolderPosition, name: this.state.editFolderName}))
            this.setState({
                editFolderName: '',
                editFolderPosition: ''
            })
        }
    }
    onEditFolderName = (e) => {
            $('.group_item').removeClass('edit_folder')
            const {dispatch} = this.props;
            dispatch(userActions.editFolder({id: this.state.editFolderPosition, name: this.state.editFolderName}))
            this.setState({
                editFolderName: '',
                editFolderPosition: ''
            })
    }
    // editOnblur =(e)=>{
    //     $('.group_item').removeClass('edit_folder')
    //     const { dispatch } = this.props;
    //     dispatch(userActions.editFolder({id:this.state.editFolderPosition, name:this.state.editFolderName}))
    //     this.setState({
    //         editFolderName:'',
    //         editFolderPosition: ''
    //     })
    // }
    openModal(event, data) {
        event.preventDefault()
        const {dispatch} = this.props;
        dispatch(modalActions.open_modal(event.currentTarget.getAttribute('data_modal'), data))
        // dispatch()
    }

    handleChangeProjectFolder = (e, id) => {
        const val = e.target.value;
        const {dispatch} = this.props;
        dispatch(userActions.projectAddFolder({id: val, folder_id: id}))
        this.setState(state => {
            const projectList = state.projectList.map((item) => {
                if (item.id === val) {
                    return {...item, folder_id: id}
                } else {
                    return item;
                }
            });
            // const projectList = this.props.getProjects.filter((item) => item.folder_id == id);
            return {
                projectList
            };
        })
    }
    onMouseLiveFolder = (e) => {
        $('.folders_select').removeClass(' bounceIn ')
        $('.folders_select').addClass('bounceOut ')
    }

    render() {
        const username = localStorage.getItem('UserName');

        return (
            <div>

                <div className="dashboard">
                    <div className="navigation">
                        <LoadingBar className="loading_section" scope="sectionBar"/>
                        <div className="nav1">
                            <div className="head">
                                <span>FOLDERS</span>
                                <div className="new_folder" onClick={this.addAction}><img src={add} alt="plus"
                                                                                          width={15}/></div>
                            </div>
                            {this.state.actionNav.addfolder &&
                            <div className="edit_inp_container">
                                <input type="text" id="addfolder" className="add_folder"
                                       placeholder="Enter Folder Name Here"
                                       name="name"
                                       value={this.state.folder.name}
                                       onChange={this.handleChangeInput1}
                                       onKeyDown={this.onEnter}
                                />
                                <div onClick={this.addFolderCheck}><img src={checked} width={14} alt=""/></div>
                            </div>
                            }
                            <ul>
                                <li className="group_item">
                                    <div className="group1">
                                        <Link to={'/dashboard'} className="link_nav">All projects</Link>
                                    </div>
                                </li>
                                {this.props.getFolders && this.props.getFolders.map(item =>
                                    <li className="group_item" key={item.id} id={item.id}>
                                        <div className="group1">
                                            <Link to={`/dashboard?${item.id}`}
                                                // onClick={(e) => { this.sortFolder(e, item.id) }}
                                                  className="link_nav">{item.name ? item.name : 'folder'}</Link>
                                            <div className="edit" data-edit-id={item.id} onClick={(e) => {
                                                this.editFolder(e, item.name)
                                            }}><img src={edit} alt="edite_but" width={12}/></div>
                                        </div>
                                        <div className="delete" data-del-id={item.id} onClick={this.delFolder}><img
                                            src={del} alt="delete_but" width={12}/></div>
                                        {item.id === this.state.editFolderPosition ?

                                            <div className="edit_inp_container">
                                                <input type="text"
                                                       name="editFolderName"
                                                       onKeyDown={this.onEditEnter}
                                                       onChange={this.handleChangeInput3}
                                                       value={this.state.editFolderName}
                                                    // onBlur={this.editOnblur}
                                                />
                                                <div onClick={this.onEditFolderName}><img src={checked} width={14} alt=""/></div>
                                            </div>
                                            : ''}
                                    </li>
                                )}

                            </ul>
                        </div>
                        <div className="nav2">
                            <div className="head">
                                <span>Users</span>
                                <div className="invite" data_modal="AddUser" onClick={(e) => {
                                    this.openModal(e, '')
                                }}><img src={add} alt="plus" width={15}/></div>
                            </div>
                            {/*{this.state.actionNav.invitePeople &&*/}
                            {/*<input type="text" className="add_folder"*/}
                            {/*id="invitePeople"*/}
                            {/*placeholder="Enter Email Address Here"*/}
                            {/*name="invitePeople"*/}
                            {/*value={this.state.people.invitePeople}*/}
                            {/*onChange={this.handleChangeInput2}*/}
                            {/*/>}*/}
                            <ul>
                                {this.props.getUsers && this.props.getUsers.map((item, index) =>
                                    item.firstname ?
                                        <li className="group_item" key={item.id}>
                                            <div className="group1">
                                                <div className="link_nav">{item.firstname}</div>
                                            </div>
                                            <div className="delete" onClick={(e) => {
                                                this.openModal(e, item.id)
                                            }} data_modal="DeleteUser"><img src={del} alt="delete_but" width={12}/>
                                            </div>
                                        </li>
                                        : ''
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="main_dashboard">
                        <div className="head">
                            <Link to={'/dashboard'} className="logo_container">
                                <img src={logo} alt="" width="32"/>
                                <span>Reflect</span>
                            </Link>
                            <div className="right">
                                <Link to={'/dashboard'}>Projects</Link>
                                <div className="rt">
                                    <span className="men_but_prof flex-center">{username && username }</span>
                                    <div className="menu_bot">
                                        <div className="logout" onClick={this.logout}>Log Out</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search_container">
                            <div className="left">
                                <div className="menu_but"><img src={menu} width="16" alt="menu button"/></div>
                                <div className="categ_name">All projects</div>
                            </div>
                            <div className="right">
                                <input type="text"
                                       placeholder="Search"
                                       name="searchProject"
                                       value={this.state.searchProject}
                                       onChange={this.searchProject}
                                />
                                <div className="new_proj" onClick={(e) => {
                                    this.openModal(e, '')
                                }} data_modal="CreateProject">New Project
                                </div>
                            </div>
                        </div>
                        <div className="proj_cont1">
                            <div className="projects_container">
                                {(this.state.projectList && this.state.projectList.length) ? this.state.projectList.map(item =>
                                    <div className="proj" key={item.id}>
                                        <div className="proj_top">
                                            <img
                                                src={item.documentation && item.documentation.img ? `https://imsba.com/${item.documentation.img}` : work}
                                                alt="" className="proj_img"/>
                                            <div className="over_proj" onMouseLeave={this.onMouseLiveFolder}>
                                                <div className="over_link" to={`project/${item.id}`}>
                                                    <Link  to={`project/${item.id}`} className="play">View Project</Link>
                                                </div>
                                                {item.documentation && item.documentation.id &&<Link
                                                    className="prev_link icon_cont"
                                                    to={`project/${item.id}/preview/${item.documentation.id}?prototype`} >
                                                    <img src={play} alt="" width="12"/>
                                                </Link>}
                                                <div className="delete icon_cont" onClick={(e) => {
                                                    this.openModal(e, item)
                                                }} data_modal="DeleteItem"><img src={del_white} alt="" width="14"/>
                                                </div>
                                                <div className="folder">
                                                    <div className="icon_cont"  onClick={(e) => {
                                                        this.selectFolder(e)
                                                    }}>
                                                        <img src={folder} alt="" width={14} className="fold_action"
                                                            />
                                                    </div>
                                                    <form className="folders_select animated">
                                                        {this.props.getFolders && this.props.getFolders.map(item1 =>
                                                            <label className="radio" key={item1.id}>
                                                                <input type="radio" value={item.id} onChange={(e) => {
                                                                    this.handleChangeProjectFolder(e, item1.id)
                                                                }} checked={item1.id === item.folder_id}/>
                                                                <span className="icon_radio"></span>
                                                                <span className="text_radio">{item1.name}</span>
                                                            </label>
                                                        )}

                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="proj_bot">
                                            <div className="name">{item.name}</div>
                                            <div className="date">
                                                {/*<span>Updated 28th Mar 19</span>*/}
                                                <span>Created:&nbsp;&nbsp;{moment(new Date(item.datetime * 1000)).format('LL hh:mm A')}</span>
                                                {item.updated
                                                    ?
                                                    <span>Updated:&nbsp;{moment(new Date(item.updated * 1000)).format('LL hh:mm A')}</span>
                                                    :
                                                    <span>Updated:&nbsp;{moment(new Date(item.datetime * 1000)).format('LL hh:mm A')}</span>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                                    : ''
                                }
                                {(this.props.getProjects && !this.props.getProjects.length) ?
                                    <div className="empt_proj">
                                        <img src={img_new_prog} width="150" alt=""/>
                                        <div className="new_proj" onClick={(e) => {
                                            this.openModal(e, '')
                                        }} data_modal="CreateProject">New Project
                                        </div>

                                    </div>:''
                                }

                            </div>
                            {this.state.projectList && this.state.projectList[0] && this.state.projectList[0].count > 10 ?
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.projectList[0].count}
                                    pageRangeDisplayed={5}
                                    firstPageText={<i className='f_link1'>First</i>}
                                    lastPageText={<i className='b_link1'>Last</i>}
                                    prevPageText={<i className='f_link2'/>}
                                    nextPageText={<i className='b_link2'/>}
                                    onChange={this.handlePageChange}
                                />
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {getFolders} = state.getFolders
    const {getUsers} = state.getUsers
    const {getProjects} = state.getProjects
    return {
        getFolders,
        getUsers,
        getProjects
    };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export {connectedDashboard as Dashboard};