/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { userActions } from '../../actions/index';
class CreateProject extends Component{
    constructor(props){
        super(props)
        // const EditContent =this.props.modals.data;
        this.state = {
            name:''

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        this.setState({
                [name]: value
        });
    }


    handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(userActions.createProject(this.state));
    }
    onBtnClick(event, data){
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    render() {

        return (
            <div  className="modal_content">
                <div className="modal_head">
                    Create Project
                </div>
                <div className="modal_bod">
                    <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                        <label >
                            <span className="label_txt">Project Name</span>
                            <input type="text"  name="name" value={this.state.name?this.state.name:''} onChange={this.handleChangeInput}/>

                        </label>
                        <div className="but_cont">
                            <button type="submit" className="new_proj">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(CreateProject);
