
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import check from '../../img/check.svg';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
class Success extends Component{
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    render() {

        return (
            <div  className="modal_content">
                <div className="modal_head">{this.props.modals.data}</div>

               <div className="modal_bod ">
                   {/*<img src={check} alt="" width="200"/>*/}
                   <div className="but_cont" onClick={()=>this.close_modal()}><button>Լավ</button></div>
               </div>


            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals
    };
}
export default connect(mapStateToProps)(Success);