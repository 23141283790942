import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {userActions} from '../../actions/index';
import {getInfo} from '../../actions/index';
import $ from "jquery";
import {Rnd} from 'react-rnd';
import moment from 'moment';
class Comments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            addComment: {
                name: '',
                parent_id: '',
            },
            addCommentMain: {
                name: '',

            },
            tempID: 'tempid1',
            replayInclID: '',
            count: 0,
            public: 0,
            scale:1,
            imgWidth:'',
            maxScale: 1,
            countChange: true,
            leftContainer:450

        }
        this.uniqID = this.uniqID.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeMain = this.handleChangeMain.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmit1 = this.handleSubmit1.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.getDocumentation && nextProps.getComments) {
            this.setState({
                    comments: nextProps.getComments
                }, function () {
                    // if(this.state.countChange){
                    //     this.setState({countChange: false})
                        this.loadImg()

                    // }
                }
            )

        }
    }
    // component
    componentDidMount() {
        let leftContainer = localStorage.getItem('leftContainer')
        if(leftContainer){
            this.setState({
                leftContainer
            })
        }
        const {dispatch} = this.props;
        dispatch(getInfo.getComments(this.props.screenId))
        dispatch(getInfo.getDocumentation(this.props.screenId));
    }

    componentDidUpdate(nextProps, nextState) {
        const {dispatch} = this.props;
        if (nextProps.screenId !== this.props.screenId) {
            dispatch(getInfo.getComments(this.props.screenId))
            dispatch(getInfo.getDocumentation(this.props.screenId));
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        const {addComment} = this.state
        this.setState({
            addComment: {
                ...addComment,
                [name]: value
            }

        });

    }

    handleChangeMain(e) {
        const {name, value} = e.target;
        const {addCommentMain} = this.state
        this.setState({
            addCommentMain: {
                ...addCommentMain,
                [name]: value
            }

        });

    }
    handlePublic =() =>{
        this.setState({
            public: !this.state.public
        })
    }
    handleSubmit(event) {
        event.preventDefault();
        const {dispatch} = this.props;
        let sendObj;

        if (this.state.comments.length) {
            this.state.comments.forEach((item) => {
                if (item.id === 'tempid1') {
                    sendObj = {...item, x:item.x/this.state.scale, y:item.y/this.state.scale, name: this.state.addCommentMain.name, public:this.state.public};

                }
                else if (item.id !== 'tempid1' && this.state.addCommentMain.name) {
                    sendObj = {
                        name: this.state.addCommentMain.name,
                        website_id: this.props.projId,
                        documentation_id: this.props.screenId,
                        public:this.state.public
                    }
                }
            });
        }
        else {
            sendObj = {
                name: this.state.addCommentMain.name,
                website_id: this.props.projId,
                documentation_id: this.props.screenId,
                public:this.state.public
            }
        }


        dispatch(userActions.addComment(sendObj))
        this.setState({
            addCommentMain: {
                name: ''
            }
        })
        $('.send_bot').removeClass('added_anotation')

    }

    handleSubmit1(event) {
        event.preventDefault();
        const {dispatch} = this.props;
        let sendObj;
        sendObj = {
            name: this.state.addComment.name,
            parent_id: this.state.addComment.parent_id,
            website_id: this.props.projId,
            documentation_id: this.props.screenId,
        }

        dispatch(userActions.addComment(sendObj))
        $('.comment_action').removeClass('active')
        this.setState({
            addComment: {
                name: '',
                parent_id: ''
            },
            replayInclID: ''
        })

    }

    uniqID() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    // add drag element
    addDragElement = (e) => {

        const self = $(e.currentTarget)
        if ($(e.target).hasClass('main_img active')) {
            var relativeX = e.pageX - self.offset().left
            var relativeY = e.pageY - self.offset().top
            this.setState(state => {
                const comments = [...state.comments, {
                    x: relativeX,
                    y: relativeY ,
                    width: 0,
                    height: 0,
                    id: this.state.tempID,
                    website_id: this.props.projId,
                    documentation_id: this.props.screenId,
                    bind_id: this.state.count,

                }]
                return {
                    comments,
                    // addComment:{
                    //     ...this.state.addComment,
                    //     binding_id:this.state.tempID
                    // }
                };
            }, function () {
                $('.main_img').removeClass('active')
                $('.comments_container').css('transform', 'translateX(0)')
            })

        }
    };
    addAnnotation = (e) => {
        $('.comments_container').css('transform', 'translateX(-100%)')
        $('.send_bot').addClass('added_anotation')
        // const uniqID = this.uniqID()
        // this.setState(state => {
        //     return {tempID: uniqID}
        // })
        $('.main_img').addClass('active')
    }
    deleteAnnotation = (e) => {
        $('.send_bot').removeClass('added_anotation')
        this.setState(state => {
            const comments = state.comments.filter((item) => item.id !== this.state.tempID);
            return {
                comments,
            };
        });
    }
    replay = (e) => {
        $('.comments_container .comment_action').removeClass('active')
        $(e.currentTarget).parent().addClass('active')
        const id = $(e.currentTarget).attr('data-id');
        const replayInclID = $(e.currentTarget).attr('data-parent-id');
        const {addComment} = this.state
        this.setState({
            addComment: {
                ...addComment,
                parent_id: id,
                name: ''
            },
            replayInclID: replayInclID

        });
    }
    cancelReplay = (e) => {
        $(e.currentTarget).parent().removeClass('active')
        const {addComment} = this.state
        this.setState({
            addComment: {
                ...addComment,
                parent_id: '',
                name: ''
            },
            replayInclID: ''

        });
    }

    completeComment(e, item) {
        let item1 = {...item, completed: 1}
        const {dispatch} = this.props;
        dispatch(userActions.completeComment(item1))
    }

    deleteComment(e, item) {
        const {dispatch} = this.props;
        dispatch(userActions.deleteComment({id: item.id}, this.props.screenId))
    }
    loadImg = (e)=>{
        let defaultScale = sessionStorage.getItem('scale')?JSON.parse(sessionStorage.getItem('scale')):null;
        let windowWidth =$(window).width() - 600;
        let width =$('.img_main2').get(0).naturalWidth
        let height =$('.img_main2').get(0).naturalHeight
        let scale;
        var scale1 =windowWidth/$('.img_main2').get(0).naturalWidth;
        this.setState({footer:this.state.footer?this.state.footer:height})
        this.setState({maxScale:scale1>=1?1:scale1})
        if(scale1 > 1){
            let param = parseFloat(width/height)
            if(param > 0.3 && param < 0.7){
                scale = 0.5
                if(defaultScale ){
                    this.resizeImg(defaultScale, width)

                }
                else{
                    this.resizeImg(scale, width)
                }
            }
            else if(param <= 0.3){
                scale=1;
                if(defaultScale ){
                    this.resizeImg(defaultScale, width)

                }
                else{
                    this.resizeImg(scale, width)
                }
            }
            else if(param >= 0.7){
                scale=1;
                if(defaultScale ){
                    this.resizeImg(defaultScale, width)

                }
                else{
                    this.resizeImg(scale, width)
                }
            }
        }
        else{
            scale = scale1
            if(defaultScale && defaultScale <= scale){
                this.resizeImg(defaultScale, width)
            }
            else{
                this.resizeImg(scale, width)
            }
        }


    }
    resizeImg =(scale, width)=>{
        this.setState({
            scale: scale,
            imgWidth: width?(width * scale):''
        })
        sessionStorage.setItem('scale', scale);
        let indElement = 0;
        this.setState(state => {
            const comments = this.props.getComments.map((item, j) => {
                if (item.x !== null && item.x !== undefined) {
                    indElement++;
                    return {...item, x: parseFloat(item.x)*scale , y: parseFloat(item.y)*scale, bind_id: indElement}
                }
                else {
                    return {...item,x: parseFloat(item.x)*scale, y: parseFloat(item.y)*scale};
                }
            });
            return {
                comments,
                count: indElement + 1
            };
        });
    }
    zoomOut = () =>{
        let width =$('.img_main2').get(0).naturalWidth
        this.setState({
            scale:(this.state.scale - 0.05)>0.2?(this.state.scale - 0.05):0.2
        }, function () {
            this.resizeImg(this.state.scale, width)
        })

    }
    zoomIn = () =>{
        let width =$('.img_main2').get(0).naturalWidth
        this.setState({
            scale:(this.state.maxScale - 0.05)>this.state.scale?(this.state.scale + 0.05):this.state.maxScale
        }, function () {
            this.resizeImg(this.state.scale, width)
        })

    }
    outOpenEdit =(e, id) =>{

            let target1 = $(`.comment_list  .comment[data-id = ${id}]`).offset().top;
            let target2 = $(`.comment_list`).offset().top;
            let target3 = $(`.comment_list`).scrollTop();
            let position = target3 + target1 - target2 - 18;
            $('.comment_list').animate({scrollTop:position}, 1000)
    }
    render() {
        return (
            <div id="comment_main_container" className="screen_cont_inside">
                <div className="zoom" >
                    <div className="zoomOut" onClick={this.zoomOut}>-</div>
                    <div>{Math.round(this.state.scale * 100)}</div>
                    <div className="zoomIn" onClick={this.zoomIn}>+</div>
                </div>
                <div className="scr_name">{this.props.getDocumentation && this.props.getDocumentation.name}</div>
                <div className="img-container1" onClick={this.addDragElement}>
                    {this.props.getDocumentation && <div className="main_img">
                        <img src={`https://imsba.com/${this.props.getDocumentation.img}`}
                             style={{width:this.state.imgWidth+'px', maxWidth:(window.innerWidth - 600)}}
                             alt="" className="main_img img_main2"/></div>}

                    {this.state.comments ? this.state.comments.map((item, index) => {
                            if (item.x && item.x != null && item.x !== undefined) {

                                return <Rnd key={index + 50}
                                            size={{width: 0, height: 0}}
                                            position={{x: item.x, y: item.y}}
                                            enableResizing={false}
                                            bounds=".img-container1"
                                            onDragStop={(e, d) => {
                                                this.setState(state => {
                                                    const comments = state.comments.map((item1, j) => {
                                                        if (j === index) {
                                                            if (item1.id !== this.state.tempID && (item1.x!==d.x || item1.y!==d.y)) {
                                                                const {dispatch} = this.props;
                                                                dispatch(userActions.editComment({
                                                                    ...item1,
                                                                    x: d.x/this.state.scale,
                                                                    y: d.y/this.state.scale
                                                                }))
                                                            }
                                                            return {...item1, x: d.x, y: d.y}
                                                        } else {
                                                            return item1;
                                                        }
                                                    });

                                                    return {
                                                        comments
                                                    };
                                                });

                                                e.preventDefault()
                                            } }

                                >
                                    <div className="onComment" id={item.id} onClick={ e => this.outOpenEdit(e, item.id)}>{item.bind_id}</div>
                                </Rnd>
                            }
                            else return false
                        }
                    )
                        : ""}
                </div>
                <Rnd
                    size={{width: this.state.leftContainer}}
                    // position={{x: 0, y:0}}
                    minWidth="400"
                    bounds="body"
                    className="dragLeft"
                    enableResizing={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                    disableDragging={true}
                    onResize={(e, direction, ref, delta, position) => {
                        this.setState({
                            leftContainer: ref.style.width,
                            ...position,
                        });
                        localStorage.setItem('leftContainer',ref.style.width)
                        this.props.leftContainer(ref.style.width)
                    }}
                >
                <div className="comments_container">
                    <div className="top">
                        <div className="name_screen">Intro Screen</div>
                        <Link to={`?prototype`} className="close_thumb flex-center" data-id="prototype"
                            >Close
                        </Link>
                    </div>
                    <div className="comment_list">
                        {this.state.comments && this.state.comments.map(item => {
                                if (item.id !== 'tempid1') {
                                    return <div className="comment" id={item.id} data-id={item.id} key={item.id}>
                                        <div className={item.completed === "1" ? "comment_incl completed" : "comment_incl"}>

                                            <div
                                                className="left">{item.user ? item.user.firstname.substring(0, 1) : ''}{item.user ? item.user.lastname.substring(0, 1) : ''}</div>
                                            <div className="right">
                                                <div className="main_comment">
                                                    <div className="name_sender">{item.user ? item.user.firstname : ''} {item.user ? item.user.lastname : ''}</div>
                                                    <div className="mess_txt">{item.name}</div>
                                                    {item.x &&( item.x != null || item.x != undefined) ?
                                                      <div className="bind_comment"  key={item.bind_id + 100}>{item.bind_id}</div>:''}
                                                </div>
                                                <div className="comment_bot">
                                                    <div className="date">
                                                        {item.datetime && <span>{moment(new Date(item.datetime* 1000)).format('hh:mm A')}</span>}
                                                    </div>
                                                    <div className="comment_action">
                                                        <div className="delete_com"
                                                             onClick={(e) => this.deleteComment(e, item)}>Delete
                                                        </div>
                                                        <div className="complete"
                                                             onClick={(e) => this.completeComment(e, item)}>Complete
                                                        </div>
                                                        <div className="complete1">Completed</div>
                                                        <div className="reply add_rep" onClick={this.replay}
                                                             data-id={item.id}>Reply
                                                        </div>
                                                        <div className="reply cancel_rep" onClick={this.cancelReplay}>Cancel
                                                            Reply
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.addComment.parent_id === item.id && !this.state.replayInclID ?
                                            <form className="reply_form" onSubmit={this.handleSubmit1}>
                                                <textarea placeholder="Write comments" name="name"
                                                          value={this.state.addComment.name}
                                                          onChange={this.handleChange}></textarea>
                                                <button className="close_thumb flex-center">Send</button>
                                            </form>
                                            : ''
                                        }
                                        {item.replies && item.replies.map(item1 =>
                                            <div className="comment" id={item1.id} key={item1.id}>
                                                <div className="comment_incl">
                                                    {/*<div className="left">{item1.short_name}</div>*/}
                                                    <div
                                                        className="left">{item1.user ? item1.user.firstname.substring(0, 1) : ''}{item1.user ? item1.user.lastname.substring(0, 1) : ''}</div>
                                                    <div className="right">
                                                        <div className="main_comment">

                                                            <div
                                                                className="name_sender">{item1.user ? item1.user.firstname : ''} {item1.user ? item1.user.lastname : ''}</div>
                                                            <div className="mess_txt">{item1.name}</div>
                                                        </div>
                                                        <div className="comment_bot">
                                                            {/*<div className="date">{item1.date}</div>*/}
                                                            <div className="date">
                                                                {item1.datetime && <span>{moment(new Date(item1.datetime* 1000)).format('hh:mm A')}</span>}
                                                            </div>
                                                            <div className="comment_action">
                                                                <div className="delete_com"
                                                                     onClick={(e) => this.deleteComment(e, item1)}>Delete
                                                                </div>
                                                                <div className="reply add_rep" onClick={this.replay}
                                                                     data-parent-id={item1.id} data-id={item.id}>Reply
                                                                </div>
                                                                <div className="reply cancel_rep"
                                                                     onClick={this.cancelReplay}>Cancel Reply
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.addComment.parent_id === item.id && this.state.replayInclID === item1.id ?
                                                    <form className="reply_form" onSubmit={this.handleSubmit1}>
                                                        <textarea placeholder="Write comments" name="name"
                                                                  value={this.state.addComment.name}
                                                                  onChange={this.handleChange}></textarea>
                                                        <button className="close_thumb flex-center">Send</button>
                                                    </form>
                                                    : ''
                                                }
                                            </div>
                                        )}
                                    </div>
                                }
                                else return false
                            }
                        )}


                    </div>
                    <form className="form_container" onSubmit={this.handleSubmit}>
                        <div className="send_top">
                            <textarea placeholder="Write comments or @mentions" name="name"
                                      value={this.state.addCommentMain.name}
                                      onChange={this.handleChangeMain}></textarea>
                            <button className="close_thumb flex-center">Send</button>
                        </div>
                        <div className="send_bot">
                            <div className="anotation add" onClick={this.addAnnotation}>Click to add Annotation</div>
                            <div className="anotation del" onClick={this.deleteAnnotation}>Delete annotation</div>
                            {this.state.public?<div className="anotation" onClick={this.handlePublic}>Private</div>:<div className="anotation" onClick={this.handlePublic}>Public</div>}

                        </div>

                    </form>

                </div>
                </Rnd>


            </div>
        );
    }
}

function mapStateToProps(state) {
    const {getComments} = state.getComments
    const {getDocumentation} = state.getDocumentation
    return {
        getComments,
        getDocumentation
    };
}

const connectedComments = connect(mapStateToProps)(Comments);
export {connectedComments as Comments};