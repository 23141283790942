import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import del from '../../img/delete-button.svg';
import menu from '../../img/menu1.svg';
import {userActions} from '../../actions/index';
import {getInfo} from '../../actions/index';
import Select from 'react-select'
import $ from "jquery";
import {Rnd} from 'react-rnd';
import ReactDragListView from 'react-drag-listview';
import * as queryString  from 'query-string';


const options2 = [
    {value: 'GET', label: 'GET'},
    {value: 'POST', label: 'POST'},
    {value: 'PUT', label: 'PUT'},
    {value: 'DELETE', label: 'DELETE'}
]
class ApiItem extends React.Component {
    constructor(props) {
        super(props)
        // this.parseUrl1 = this.parseUrl1.bind(this)
        this.state = {
            data: {
                url: this.props.item.url ? this.props.item.url : '',
                description: this.props.item.description ? this.props.item.description : '',
                params: this.props.item.params ? JSON.parse(this.props.item.params).concat([{key: "", value: '', description: ''}]) : [{key: "", value: '', description: ''}],
                authorization: this.props.item.authorization ? JSON.parse(this.props.item.authorization).concat([{key: "", value: '', description: ''}]) : [{key: "", value: '', description: ''}],
                headers: this.props.item.headers ? JSON.parse(this.props.item.headers).concat([{key: "", value: '', description: ''}]) : [{key: "", value: '', description: ''}],
                body: this.props.item.body? JSON.parse(this.props.item.body).concat([{key: "", value: '', description: ''}]) : [{key: "", value: '', description: ''}],
                response: this.props.item.response ? this.props.item.response : '',
                method: this.props.item.method ? this.props.item.method : "GET",
                template: this.props.item.template && this.props.item.template !== '0' ? this.props.item.template : false,
            },
            method: {
                value: this.props.item.method ? this.props.item.method : "GET",
                label: this.props.item.method ? this.props.item.method : "GET"
            },
            errorJson: false,
            tabItem: 'params',
            templates: null


        }



    }
    componentDidUpdate(nextProps) {
        if (nextProps.item !== this.props.item) {
            this.setState({
                data: {
                    url: this.props.item.url ? this.props.item.url : '',
                    description: this.props.item.description ? this.props.item.description : '',
                    params: this.props.item.params ? JSON.parse(this.props.item.params) : [{key: "", value: '', description: ''}],
                    authorization: this.props.item.authorization ? JSON.parse(this.props.item.authorization) : [{key: "", value: '', description: ''}],
                    headers: this.props.item.headers ? JSON.parse(this.props.item.headers) : [{key: "", value: '', description: ''}],
                    body: this.props.item.body ? JSON.parse(this.props.item.body) : [{key: "", value: '', description: ''}],
                    response: this.props.item.response ? this.props.item.response : '',
                    method: this.props.item.method ? this.props.item.method : "GET",
                    template: this.props.item.template && this.props.item.template !== '0' ? this.props.item.template : false,
                },
                method: {
                    value: this.props.item.method ? this.props.item.method : "GET",
                    label: this.props.item.method ? this.props.item.method : "GET"
                },
            })
        }

    }
    // auto_grow = (element)=> {
    //
    //     // element.currentTarget.style.height = offset +(element.currentTarget.scrollHeight)+"px";
    //     var text = $(element.currentTarget).val(),
    //         matches = text.match(/\n/g),
    //         breaks = matches ? matches.length : 2;
    //     $(element.currentTarget).attr('rows',breaks + 2);
    //
    //
    // }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const {data} = this.state
        this.setState({
            data: {
                ...data,
                [name]: value
            }
        });
    }
    parseUrl1  = (value) =>{
        let val = queryString.parseUrl(value, {sort:false})
        let params = [];
        for (var prop in val.query) {
            if(prop!=="") {
                params = [...params, {key: prop, value: val.query[prop] ? val.query[prop] : '', description: ''}]
            }
        }
        return [...params, {key: '', value:  '', description: ''}]
    }
    handleChangeUrl = (e) =>{
        const target = e.target;
        const value = target.value;
        const {data} = this.state
        let param = this.parseUrl1(value)
        this.setState({
            data: {
                ...data,
                url: value,
                params: param
            }
        });
    }
    handleSubmit = (e) => {
        this.setState({errorJson: false})
        // if(this.state.response){
        const {dispatch} = this.props;
        let sendObj;
        sendObj = {
            url: this.state.data.url,
            description: this.state.data.description,
            params: this.state.data.params ? JSON.stringify(this.state.data.params) : '',
            authorization: this.state.data.authorization ? JSON.stringify(this.state.data.authorization) : '',
            headers: this.state.data.headers ? JSON.stringify(this.state.data.headers) : '',
            body: this.state.data.body ? JSON.stringify(this.state.data.body) : '',
            response: '',
            method: this.state.data.method,
            name: this.props.item.name,
            id: this.props.item.id,
            template: this.state.data.template,
            documentation_id: this.props.item.documentation_id,
            website_id: this.props.item.website_id
        }
        if (this.state.data.response) {
            try {
                JSON.parse(this.state.data.response)
                sendObj.response = this.state.data.response;
                dispatch(userActions.editApi(sendObj, this.props.accId))
                this.props.closeAllAPi()

            }

            catch (e) {
                this.setState({errorJson: true})
            }
        }

        else {
            dispatch(userActions.editApi(sendObj, this.props.accId))
            this.props.closeAllAPi()
        }
    }
    handleChange1 = (selectedOption, el) => {
        this.setState({[el.name]: selectedOption});
        const {data} = this.state
        this.setState({
            data: {
                ...data,
                [el.name]: selectedOption.value
            }
        });
    }
    handleChange2 = (selectedOption, el) => {
        this.setState({[el.name]: selectedOption});
        if (selectedOption) {
            this.props.dispatch(getInfo.getApiOne(selectedOption.value, this.props.item.id))
        }
        else {
            this.props.dispatch(userActions.removeApiOne(this.props.item.id))
        }


    }
    handleShareholderNameChange = (idx, dataName) => evt => {
        const newShareholders = this.state.data[dataName].map((shareholder, sidx) => {
            if (idx !== sidx) {return shareholder;}
            else{
                if(evt.target.name === "key" && (shareholder.value ==='' || shareholder.value===`{{${shareholder.key}}}`)){
                    return {...shareholder, [evt.target.name]: evt.target.value, value:`{{${evt.target.value}}}`};
                }
                else {
                    return {...shareholder, [evt.target.name]: evt.target.value};
                }

            }

        });
        // const {data} = this.state
        let x ='';
        if(dataName==='params'){
            const {data} = this.state
            console.log('da')
            let url1 = newShareholders;
            let url2 = queryString.parseUrl(this.state.data.url, {sort:false})
            if(url1.length>1){
                x = url1.map(
                    (item, index)=> `${item.key}=${item.value}`
                ).slice(0, -1).join('&')
            }
            else {
                x = url1.map(
                    (item, index)=> `${item.key}=${item.value}`
                ).join('&')
            }

            let url3 = `${url2.url}?${x} `
            console.log(x)
            console.log(url3)
            this.setState({
                data: {
                    ...data,
                    url: url3,
                    params: (newShareholders.length === (idx+1))? newShareholders.concat([{key: "", value: '', description: ''}]) : newShareholders
                }
            }
            // , function () {
            //     if(this.state.data[dataName].length === (idx+1) ){
            //         this.setState({
            //             data: {
            //                 ...data,
            //                 params: this.state.data.params.concat([{key: "", value: '', description: ''}])
            //             }
            //         });
            //     }
            //
            //
            // }
            );
        }
        else{
            const {data} = this.state
            this.setState({
                data: {
                    ...data,
                    [dataName]: newShareholders
                }
            }, function () {
                if(this.state.data[dataName].length === (idx+1) ){
                    this.setState({
                        data: {
                            ...data,
                            [dataName]: this.state.data[dataName].concat([{key: "", value: '', description: ''}])
                        }
                    });
                }


            });
        }



    };
    // addRow = (dataName) => () => {
    //     const {data} = this.state
    //     this.setState({
    //         data: {
    //             ...data,
    //             [dataName]: this.state.data[dataName].concat([{key: "", value: '', description: ''}])
    //         }
    //     });
    // }
    handleRemoveShareholder = (idx, dataName) => () => {
        const {data} = this.state
        this.setState({
            data: {
                ...data,
                [dataName]: this.state.data[dataName].filter((s, sidx) => idx !== sidx)
            }
        }, function () {
            const {data} = this.state
            if(dataName==='params'){
                let url1 = this.state.data.params;
                let url2 = queryString.parseUrl(this.state.data.url, {sort:false})

                let x = url1.map(
                    (item, index)=> `${item.key}=${item.value}`
                ).slice(0, -1).join('&')
                let url3 = `${url2.url}?${x} `
                this.setState({
                    data: {
                        ...data,
                        url: url3,
                    }
                });
            }
        });
    };
    tabClick = (e) => {
        const self = $(e.currentTarget)
        if (!self.hasClass('active')) {
            const dataid = self.attr('data-id')
            $('.tabItem').removeClass('active')
            $(`.tab_body`).removeClass('active')
            self.addClass('active')
            $(`.tab_body${dataid}`).addClass('active')
            let dataid1 = dataid.replace('#', '');
            this.setState({
                tabItem:dataid1
            })
        }
    }

    render() {
        const that = this;
        const {data} = this.state
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data1 = that.state.data[that.state.tabItem];

                const item = data1.splice(fromIndex, 1)[0];
                data1.splice(toIndex, 0, item);
                that.setState({
                    data:{
                        ...data,
                        [that.state.tabItem]:data1
                    }
                }
                , function () {
                    if(that.state.tabItem==='params'){
                        let url1 = this.state.data.params;
                        let url2 = queryString.parseUrl(this.state.data.url, {sort:false})

                        let x = url1.map(
                            (item, index)=> `${item.key}=${item.value}`
                        ).slice(0, -1).join('&')
                        let url3 = `${url2.url}?${x} `
                        this.setState({
                            data: {
                                ...data,
                                url: url3,
                            }
                        });
                    }
                });
            },
            handleSelector: ".drag"
        };
        return (
            <div className="bot_api">
                <label className="full_width">
                    <span className="bold_api">Copy from template</span>
                    {this.props.templates &&
                    <Select name="templates"
                            className="basic-single"
                            isSearchable="true"
                            value={this.state.templates}
                            onChange={this.handleChange2}
                            isClearable="true"
                            options={this.props.templates}
                            placeholder="Please select from templates"
                    />
                    }
                </label>
                <div className="or">or</div>
                <div className="manual_api">

                    <div className="col1">
                        <Select
                            className="basic-single1"
                            name="method"
                            value={this.state.method}
                            onChange={this.handleChange1}
                            options={options2}
                        />
                        <input type="text" className="inpUrl" name="url" placeholder="API url"
                               onChange={this.handleChangeUrl} value={this.state.data.url}/>
                    </div>
                    <div className="col1">
                        <label >
                            <span className="bold_api">Description</span>
                            <input type="text" className="inpDesc" name="description" onChange={this.handleChange}
                                   value={this.state.data.description}/>
                        </label>
                    </div>
                    <div className="col2">
                        <div className="tab_head">
                            <div className="tabItem active" data-id="#params" onClick={this.tabClick}>Params</div>
                            <div className="tabItem" data-id="#authorization" onClick={this.tabClick}>Authorization
                            </div>
                            <div className="tabItem" data-id="#headers" onClick={this.tabClick}>Headers</div>
                            <div className="tabItem" data-id="#body" onClick={this.tabClick}>Body</div>
                        </div>
                        <div className="tab_body active" id="params">
                            <div className="row1">
                                <ReactDragListView {...this.dragProps}>
                                <table>
                                    <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Key</td>
                                        <td>Value</td>
                                        <td>Description</td>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.data.params.map((shareholder, idx) => (
                                        <tr key={idx}>
                                            <td className="drag"><img src={menu} width='12' alt=""/></td>
                                            {this.state.data.params.length !== (idx+1) ? <td onClick={this.handleRemoveShareholder(idx, 'params')}>
                                                <img src={del}alt="delete_but"className="del_icon"width={14}/>
                                            </td>
                                                :
                                                <td></td>
                                            }
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Key"
                                                    name="key"
                                                    value={shareholder.key}
                                                    onChange={this.handleShareholderNameChange(idx, 'params')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="value"
                                                    placeholder="Value"
                                                    value={shareholder.value}
                                                    onChange={this.handleShareholderNameChange(idx, 'params')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="description"
                                                    placeholder="Description"
                                                    value={shareholder.description}
                                                    onChange={this.handleShareholderNameChange(idx, 'params')}
                                                />
                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </table>
                                </ReactDragListView>
                                {/*<div className="addrow" onClick={this.addRow('params') }>+ Add row</div>*/}
                            </div>

                        </div>
                        <div className="tab_body" id="authorization">
                            <div className="row1">
                                <ReactDragListView {...this.dragProps}>
                                <table>
                                    <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Key</td>
                                        <td>Value</td>
                                        <td>Description</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.data.authorization.map((shareholder, idx) => (
                                        <tr key={idx}>
                                            <td className="drag"><img src={menu} width='12' alt=""/></td>
                                            {this.state.data.authorization.length !== (idx+1) ? <td onClick={this.handleRemoveShareholder(idx, 'authorization')}><img
                                                src={del} alt="delete_but" className="del_icon" width={14}/></td>
                                                    :
                                                <td></td>}
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Key"
                                                    name="key"
                                                    value={shareholder.key}
                                                    onChange={this.handleShareholderNameChange(idx, 'authorization')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="value"
                                                    placeholder="Value"
                                                    value={shareholder.value}
                                                    onChange={this.handleShareholderNameChange(idx, 'authorization')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="description"
                                                    placeholder="Description"
                                                    value={shareholder.description}
                                                    onChange={this.handleShareholderNameChange(idx, 'authorization')}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                </ReactDragListView>
                                {/*<div className="addrow" onClick={this.addRow('authorization') }>+ Add row</div>*/}
                            </div>
                        </div>
                        <div className="tab_body" id="headers">
                            <div className="row1">
                                <ReactDragListView {...this.dragProps}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Key</td>
                                            <td>Value</td>
                                            <td>Description</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.data.headers.map((shareholder, idx) => (
                                            <tr key={idx}>
                                                <td className="drag"><img src={menu} width='12' alt=""/></td>
                                                {this.state.data.headers.length !== (idx+1) ? <td onClick={this.handleRemoveShareholder(idx, 'headers')}>
                                                    <img src={del}alt="delete_but" className="del_icon"width={14}/>
                                                </td>
                                                        :
                                                    <td></td>
                                                }

                                                <td>
                                                    <input
                                                        type="text"
                                                        placeholder="Key"
                                                        name="key"
                                                        value={shareholder.key}
                                                        onChange={this.handleShareholderNameChange(idx, 'headers')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="value"
                                                        placeholder="Value"
                                                        value={shareholder.value}
                                                        onChange={this.handleShareholderNameChange(idx, 'headers')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="description"
                                                        placeholder="Description"
                                                        value={shareholder.description}
                                                        onChange={this.handleShareholderNameChange(idx, 'headers')}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </ReactDragListView>
                                {/*<div className="addrow" onClick={this.addRow('headers') }>+ Add row</div>*/}
                            </div>
                        </div>
                        <div className="tab_body" id="body">
                            <div className="row1">
                                <ReactDragListView {...this.dragProps}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Key</td>
                                            <td>Value</td>
                                            <td>Description</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.data.body.map((shareholder, idx) => (
                                            <tr key={idx}>
                                                <td className="drag"><img src={menu} width='12' alt=""/></td>
                                                {this.state.data.body.length !== (idx + 1) ?
                                                    <td onClick={this.handleRemoveShareholder(idx, 'body')}><img
                                                        src={del}
                                                        alt="delete_but"
                                                        className="del_icon"
                                                        width={14}/>
                                                    </td>
                                                    :
                                                    <td></td>
                                                }
                                                <td>
                                                    <input
                                                        type="text"
                                                        placeholder="Key"
                                                        name="key"
                                                        value={shareholder.key}
                                                        onChange={this.handleShareholderNameChange(idx, 'body')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="value"
                                                        placeholder="Value"
                                                        value={shareholder.value}
                                                        onChange={this.handleShareholderNameChange(idx, 'body')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="description"
                                                        placeholder="Description"
                                                        value={shareholder.description}
                                                        onChange={this.handleShareholderNameChange(idx, 'body')}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </ReactDragListView>
                                {/*<div className="addrow" onClick={this.addRow('body') }>+ Add row</div>*/}
                            </div>
                        </div>
                        <label className="response">
                            <span className="bold_api">Response Example:</span>
                            <textarea name="response" onKeyUp={this.auto_grow} onChange={this.handleChange}
                                      value={this.state.data.response}></textarea>
                            {this.state.errorJson && <span className="valid_json">Don't valid JSON</span>}
                        </label>
                        <div className="save">
                            <label className="check_temp">
                                <span>Save as template</span>
                                <input type="checkbox"
                                       name="template"
                                       checked={this.state.data.template}
                                       onChange={this.handleChange}/>
                            </label>

                            <div className="save_but" onClick={this.handleSubmit }>save</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Api extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            api: [],
            addApi: {
                name: '',
            },
            tempID: 'tempid1',
            selectedOption: null,
            errorReq: false,
            templates: [],
            editApi: '',
            public: 0,
            scale:1,
            imgWidth:'',
            maxScale: 1,
            leftContainer:450

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.getDocumentation && nextProps.getApi) {
            this.setState({
                api: nextProps.getApi
                }, function () {
                    this.loadImg()
                }
            )

        }
        if (nextProps.getApiTemplates) {
            this.setState(state => {
                const templates = nextProps.getApiTemplates.map((item1) => {
                    return {value: item1.id, label: item1.name}

                });
                return {
                    templates
                };

            })
        }
    }

    componentDidMount() {
        let leftContainer = localStorage.getItem('leftContainer')
        if(leftContainer){
            this.setState({
                leftContainer
            })
        }
        const {dispatch} = this.props;
        dispatch(getInfo.getApi(this.props.screenId))
        dispatch(getInfo.getDocumentation(this.props.screenId));
        dispatch(getInfo.getApiTemplates(this.props.accountID));
    }

    componentDidUpdate(nextProps, nextState) {
        const {dispatch} = this.props;
        if (nextProps.screenId !== this.props.screenId) {
            dispatch(getInfo.getApi(this.props.screenId))
            dispatch(getInfo.getDocumentation(this.props.screenId));
            dispatch(getInfo.getApiTemplates(this.props.accountID));
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        const {addApi} = this.state
        this.setState({
            addApi: {
                ...addApi,
                [name]: value
            }

        });

    }
    handlePublic =() =>{
        this.setState({
            public: !this.state.public
        })
    }
    handleSubmit(event) {
        event.preventDefault();
        const {dispatch} = this.props;
        this.setState({errorReq: false})
        let sendObj;
        if (this.state.api.length) {
            this.state.api.forEach((item) => {
                if (item.id === 'tempid1' && this.state.addApi.name) {
                    sendObj = {...item, x:item.x/this.state.scale, y:item.y/this.state.scale, name: this.state.addApi.name, public: this.state.public};
                    dispatch(userActions.addApi(sendObj))
                    this.setState({errorReq: false})
                    this.setState({
                        addApi: {
                            name: ''
                        }
                    })
                    $('.send_bot').removeClass('added_anotation')
                }
                else {
                    this.setState({errorReq: true})
                }
            });
        }
        else {
            this.setState({errorReq: true})
        }




    }

    // add drag element
    addDragElement = (e) => {
        const self = $(e.currentTarget)
        if ($(e.target).hasClass('main_img active')) {
            var relativeX = e.pageX - self.offset().left
            var relativeY = e.pageY - self.offset().top
            this.setState(state => {
                const api = [...state.api, {
                    x: relativeX ,
                    y: relativeY ,
                    width: 0,
                    height: 0,
                    id: this.state.tempID,
                    website_id: this.props.projId,
                    documentation_id: this.props.screenId,
                    bind_id: 'NEW',

                }]
                return {
                    api,
                };
            }, function () {
                $('.main_img').removeClass('active')
                $('.comments_container').css('transform', 'translateX(0)')
            })

        }
    };
    addAnnotation = (e) => {
        $('.comments_container').css('transform', 'translateX(-100%)')
        $('.send_bot').addClass('added_anotation')
        $('.main_img').addClass('active')
    }
    deleteAnnotation = (e) => {
        $('.send_bot').removeClass('added_anotation')
        this.setState(state => {
            const api = state.api.filter((item) => item.id !== this.state.tempID);
            return {
                api,
            };
        });
    }
    editApi = (e, id) => {
        const self = $(e.currentTarget)
        if (!self.hasClass('active')) {
            $('.but_edit').removeClass('active')
            self.addClass('active')
            this.setState({
                editApi: id
            });
        }
        else {
            self.removeClass('active')
            this.setState({
                editApi: ''
            });
        }
    }
    closeAllAPi = () => {
        $('.but_edit').removeClass('active')
        this.setState({
            editApi: ''
        });
    }
    outOpenEdit =(e, id) =>{
        e.preventDefault();
        e.stopPropagation();
        // e.stopImmediatePropagation();
        $('.but_edit').removeClass('active')
        $(`.comment_list  .api[data-open-id = ${id}] .but_edit`).addClass('active')

        this.setState({
            editApi: id
        }, function () {
            let target1 = $(`.comment_list  .api[data-open-id = ${id}]`).offset().top;
            let target2 = $(`.comment_list`).offset().top;
            let target3 = $(`.comment_list`).scrollTop();
            let position = target3 + target1 - target2;
            $('.comment_list').animate({scrollTop:position}, 1000)
        });

    }
    delApi = (e, id) => {
        const {dispatch} = this.props;
        dispatch(userActions.delApi({id: id}, this.props.accountID))
    }
    loadImg = (e)=>{
        // localStorage.setItem('scale','1')
        let defaultScale = sessionStorage.getItem('scale')?JSON.parse(sessionStorage.getItem('scale')):null;
        let windowWidth =$(window).width() - 600;
        let width =$('.img_main2').get(0).naturalWidth
        let height =$('.img_main2').get(0).naturalHeight
        let scale;
        var scale1 =windowWidth/$('.img_main2').get(0).naturalWidth;
        this.setState({footer:this.state.footer?this.state.footer:height})
        this.setState({maxScale:scale1>=1?1:scale1})
        if(scale1 > 1){
            let param = parseFloat(width/height)
            if(param > 0.3 && param < 0.7){
                scale = 0.5
                if(defaultScale){
                    this.resizeImg(defaultScale, width)

                }
                else{
                    this.resizeImg(scale, width)
                }
            }
            else if(param <= 0.3){
                scale=1;
                if(defaultScale ){
                    this.resizeImg(defaultScale, width)

                }
                else{
                    this.resizeImg(scale, width)
                }
            }
            else if(param >= 0.7){
                scale=1;
                if(defaultScale){
                    this.resizeImg(defaultScale, width)

                }
                else{
                    this.resizeImg(scale, width)
                }
            }
        }
        else{
            scale = scale1
            if(defaultScale && defaultScale <= scale){
                this.resizeImg(defaultScale, width)
            }
            else{
                this.resizeImg(scale, width)
            }
        }


    }
    resizeImg =(scale, width)=>{
        this.setState({
            scale: scale,
            imgWidth: width?(width * scale):''
        })
        sessionStorage.setItem('scale', scale);
        let indElement = 0;
        this.setState(state => {
            const api = this.props.getApi.map((item, j) => {
                if (item.x !== null && item.x !== undefined) {
                    indElement++;
                    return {...item, x: parseFloat(item.x)*scale , y: parseFloat(item.y)*scale, bind_id: indElement}
                }
                else {
                    return {...item,x: parseFloat(item.x)*scale, y: parseFloat(item.y)*scale};
                }
            });
            return {
                api,
                count: indElement + 1
            };
        });
    }
    zoomOut = () =>{
        let width =$('.img_main2').get(0).naturalWidth
        this.setState({
            scale:(this.state.scale - 0.05)>0.2?(this.state.scale - 0.05):0.2
        }, function () {
            this.resizeImg(this.state.scale, width)
        })

    }
    zoomIn = () =>{
        let width =$('.img_main2').get(0).naturalWidth
        this.setState({
            scale:(this.state.maxScale - 0.05)>this.state.scale?(this.state.scale + 0.05):this.state.maxScale
        }, function () {
            this.resizeImg(this.state.scale, width)
        })

    }
    render() {
        return (
            <div id="comment_main_container" className="screen_cont_inside">
                <div className="zoom" >
                    <div className="zoomOut" onClick={this.zoomOut}>-</div>
                    <div>{Math.round(this.state.scale * 100)}</div>
                    <div className="zoomIn" onClick={this.zoomIn}>+</div>
                </div>
                <div className="scr_name">{this.props.getDocumentation && this.props.getDocumentation.name}</div>
                <div className="img-container1" onClick={this.addDragElement}>
                    <div className="main_img">
                        {this.props.getDocumentation && <div className="main_img">
                            <img src={`https://imsba.com/${this.props.getDocumentation.img}`}
                             style={{width:this.state.imgWidth+'px', maxWidth:(window.innerWidth - 600)}}
                             alt="" className="main_img img_main2"/></div>}
                    </div>
                    {this.state.api ? this.state.api.map((item, index) => {
                            if (item.x !== null && item.x !== undefined) {

                                return <Rnd key={index + 50}
                                            size={{width: 0, height: 0}}
                                            position={{x: item.x, y: item.y }}
                                            enableResizing={false}
                                            bounds=".img-container1"
                                            onDragStop={(e, d) => {
                                                this.setState(state => {
                                                    const api = state.api.map((item1, j) => {
                                                        if (j === index) {
                                                            if (item1.id !== this.state.tempID && (item1.x!==d.x || item1.y!==d.y) ) {
                                                                const {dispatch} = this.props;
                                                                dispatch(userActions.editApi({...item1, x: d.x/this.state.scale, y: d.y/this.state.scale}))
                                                            }
                                                            return {...item1, x: d.x, y: d.y }
                                                        } else {
                                                            return item1;
                                                        }
                                                    });

                                                    return {
                                                        api
                                                    };
                                                });

                                                e.preventDefault()
                                            }
                                            }

                                >
                                    <div className="onComment" onClick={ e => this.outOpenEdit(e, item.id)} id={item.id}>{index + 1}</div>
                                </Rnd>
                            }
                            else return false
                        }
                    )
                        : ""}
                </div>
                <Rnd
                     size={{width: this.state.leftContainer}}
                     // position={{x: 0, y:0}}
                     minWidth="400"
                     bounds="body"
                     className="dragLeft"
                     enableResizing={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                     disableDragging={true}
                     onResize={(e, direction, ref, delta, position) => {
                         this.setState({
                             leftContainer: ref.style.width,
                             ...position,
                         });
                         localStorage.setItem('leftContainer',ref.style.width)
                         this.props.leftContainer(ref.style.width)

                     }}
                >
                    <div className="comments_container">
                        <div className="top">
                            <div className="name_screen">Intro Screen</div>
                            <Link to={`?prototype`} className="close_thumb flex-center" data-id="prototype"
                            >Close</Link>
                        </div>
                        <div className="comment_list ">
                            {this.state.api && this.state.api.map((item, index) => {
                                    if (item.id !== 'tempid1') {
                                        return <div className="api" key={item.id} data-open-id={item.id}>

                                            <div className="top_api">
                                                <div className="top_left_api">
                                                    <div className="api_number">{index + 1}</div>
                                                    <div className="api_name">{item.name}</div>
                                                </div>
                                                <div className="top_right_api">
                                                    <div className="edit_api but_edit"
                                                         onClick={(e) => this.editApi(e, item.id)}>
                                                        <span className="edit_span">Edit</span>
                                                        <span className="close_span">Close</span>
                                                    </div>
                                                    <div className="edit_api del_api" onClick={(e) => this.delApi(e, item.id)}>
                                                        Delete
                                                    </div>
                                                </div>
                                            </div>


                                            {this.state.editApi === item.id &&
                                            <ApiItem item={item} dispatch={this.props.dispatch} templates={this.state.templates}
                                                     closeAllAPi={this.closeAllAPi} accId={this.props.accountID}/>}

                                        </div>
                                    }
                                    else return false
                                }
                            )}


                        </div>
                        <form className="form_container" onSubmit={this.handleSubmit}>
                            <div className="send_top">
                            <textarea placeholder="API Name" name="name" value={this.state.addApi.name}
                                      onChange={this.handleChange}
                                      onKeyPress={event => {
                                          if (event.key === "Enter") {
                                              this.handleSubmit(event);
                                          }
                                      }
                                      }>

                            </textarea>
                                <button className="close_thumb flex-center">Create</button>
                            </div>
                            <div className="send_bot">
                                <div className="anotation add" onClick={this.addAnnotation}>Click to add Annotation
                                    (Required)
                                </div>
                                <div className="anotation del" onClick={this.deleteAnnotation}>Delete annotation</div>
                                {this.state.errorReq && <div className="valid_json">Required name and annotation</div>}
                                {this.state.public?<div className="anotation" onClick={this.handlePublic}>Private</div>:<div className="anotation" onClick={this.handlePublic}>Public</div>}

                            </div>
                        </form>

                    </div>
                </Rnd>



            </div>
        );
    }
}

function mapStateToProps(state) {
    const {getApi} = state.getApi
    const {getDocumentation} = state.getDocumentation
    const {getApiTemplates} = state.getApiTemplates
    return {
        getApi,
        getDocumentation,
        getApiTemplates
    };
}

const connectedApi = connect(mapStateToProps)(Api);
export {connectedApi as Api};