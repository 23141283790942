import React from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../actions/index';
import {  getInfo } from '../../actions';
import $ from "jquery";
import {history} from '../../helpers';
import {Rnd} from 'react-rnd';
import {  notificationActions } from '../../actions';
class Prototype extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list:[],
            tempID: 'ListTempID',
            thumbList: [],
            selectThumb: '',
            selectItem: '',
            currentPosition:[],
            delid:[],
            header:0,
            footer:0,
            scale:1,
            imgWidth:'',
            maxScale: 1,
            visibHeader: false,
            visibFooter: false

        }
        this.uniqID = this.uniqID.bind(this)



    }
    componentWillUnmount(){
        $('.img-container').off('mousemove')
    }
    componentDidMount() {
        this.props.getLinks(this.props.screenId)
        this.props.getDoc(this.props.screenId)
        document.getElementById('bot-flex').addEventListener('wheel', (e) => {
            e.preventDefault()
            document.getElementById('bot-flex').scrollLeft += (e.deltaY * 10);
        })
        $('body').on('mouseup',function () {
            if($('.img-container').hasClass('moving')){
                $('.img-container').removeClass('moving')
                $('.img-container').addClass('complete')
                $('.main_prototype.flex-center').addClass('padding_bottom_400')
                $('.bot_container').slideDown()
                $('.img-container').off('mousemove')
            }
        })
        this.setState({
            thumbList: this.props.thumbs,
        })

    }
    componentWillReceiveProps(nextProps){
        if(nextProps.getDocumentation && nextProps.getImgLinks && nextProps.getImgLinks!==this.state.list){
            this.setState({
                list: nextProps.getImgLinks,
                header:nextProps.getDocumentation.header,
                footer:nextProps.getDocumentation.footer
            }, function () {
                this.loadImg()
            })
        }
    }
    // static getDerivedStateFromProps(nextProps, prevState){
    //     if(nextProps.getImgLinks!==prevState.getImgLinks){
    //         return { list: nextProps.getImgLinks};
    //     }
    //     else return null;
    // }
    componentDidUpdate(nextProps, nextState){

        if( nextProps.screenId !== this.props.screenId){
            this.props.getLinks(this.props.screenId)
            this.props.getDoc(this.props.screenId)

        }
    }

    uniqID() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    // add drag element
    addDragElement = (e) => {

        const uniqID = this.uniqID()
        const self = $(e.currentTarget)


        if ($(e.target).hasClass('main_img') ) {
            self.addClass('moving')
            var relativeX = e.pageX - self.offset().left
            var relativeY = e.pageY - self.offset().top
            this.setState(state => {
                const list1 = state.list.filter((item, j) => item.link_id !== state.tempID);
                const list = [...list1, {
                    startX:relativeX,
                    startY:relativeY,
                    x: relativeX,
                    y: relativeY,
                    width: 0,
                    height: 0,
                    link_id: this.state.tempID,
                    name: uniqID,
                    website_id:this.props.projId,
                    documentation_id:this.props.screenId,
                    status:'create'
                }]
                return {
                    list,
                    selectItem: '',
                    selectThumb: ''
                };
            }, function () {
                $('.itemdrag').removeClass('selected_element');
                $(`[data-id=${uniqID}]`).addClass('selected_element');
                self.removeClass('complete')
            })

        }
    };
    mouseMove = (e) => {
        const self = $(e.currentTarget)
        if (self.hasClass('moving') && !self.hasClass('complete') ) {
            var relativeX = e.pageX - self.offset().left
            var relativeY = e.pageY - self.offset().top

            this.setState(state => {
                const list = state.list.map((item) => {
                    if (item.link_id === 'ListTempID') {
                        var maxWidth1= $('.img-container').width() - item.startX;
                        var maxWidth2= $('.img-container').width() - maxWidth1;
                        var maxHeight1= $('.img-container').height() - item.startY;
                        var maxHeight2= $('.img-container').height() - maxHeight1;
                        if(item.startX <= relativeX && item.startY <= relativeY){
                            return {
                                ...item,
                                x:item.startX,
                                y:item.startY,
                                width: (relativeX - item.startX)>=maxWidth1?maxWidth1:(relativeX - item.startX) ,
                                height: (relativeY - item.startY)>=maxHeight1?maxHeight1:(relativeY - item.startY)
                            }
                        }
                        else if(item.startX >= relativeX && item.startY >= relativeY){
                            return {
                                ...item,
                                x:(item.startX -relativeX)>=maxWidth2?0:relativeX,
                                y:(item.startY -relativeY)>=maxHeight2?0:relativeY,
                                width: (item.startX -relativeX)>=maxWidth2?maxWidth2:(item.startX -relativeX) ,
                                height: (item.startY - relativeY)>=maxHeight2?maxHeight2: (item.startY - relativeY)
                            }
                        }
                        else if(item.startX <= relativeX && item.startY >= relativeY){
                            return {
                                ...item,
                                x:item.startX,
                                y:(item.startY -relativeY)>=maxHeight2?0:relativeY,
                                width: (relativeX - item.startX)>=maxWidth1?maxWidth1:(relativeX - item.startX)  ,
                                height: (item.startY - relativeY)>=maxHeight2?maxHeight2: (item.startY - relativeY)
                            }
                        }
                        else if(item.startX >= relativeX && item.startY <= relativeY){
                            return {
                                ...item,
                                x:(item.startX -relativeX)>=maxWidth2?0:relativeX,
                                y:item.startY,
                                width:(item.startX -relativeX)>=maxWidth2?maxWidth2:(item.startX -relativeX)  ,
                                height: (relativeY - item.startY)>=maxHeight1?maxHeight1:(relativeY - item.startY)
                            }
                        }
                    } else {
                        return item;
                    }
                });

                return {
                    list,
                };
            });

        }
    }
    mouseUp = (e) => {
        const self = $(e.currentTarget)

        if(self.hasClass('moving')){
            self.removeClass('moving')
            self.addClass('complete')
            $('.bot_container').slideDown();
            self.off('mousemove')
        }

    }
    componentPosition = (id) => {
        let elment1 = document.getElementById(id)
        let element2 = document.querySelector('.bot_container #bot-flex')
        var x = elment1.offsetWidth
        var z = element2.offsetWidth
        var n = elment1.offsetLeft
        let position = n - z / 2 + x / 2

        $('.bot_container #bot-flex').animate({scrollLeft: position}, 1000);
    }
    // select drag element
    SelectDragItem1 = (e) => {
        var relativeX = e.pageX
        var relativeY = e.pageY
        this.setState(state => {
            return {
                currentPosition:[relativeX, relativeY]
            };
        })
    }
    SelectDragItem = (e) => {
            if(e.pageX === this.state.currentPosition[0] && e.pageY===this.state.currentPosition[1]){
                $('.itemdrag').removeClass('selected_element');
                $(e.currentTarget).addClass('selected_element');
                if($(e.target).attr('class') !=='del_drag'){
                    $('.bot_container').slideDown()
                }
                const itemID = $(e.currentTarget).attr('data-id')
                const itemSelectID = $(e.currentTarget).attr('data-select-id')
                // let position = $('.bot_container #' + itemSelectID).position().left
                if (itemSelectID !== "ListTempID") {
                    this.componentPosition(itemSelectID)
                    this.setState(state => {
                        const list = state.list.filter((item, j) => item.id !== state.tempID);
                        return {
                            list,
                            selectItem: itemID,
                            selectThumb: itemSelectID
                        };
                    })
                }
            }

    }
    //delete drag item
    delDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.itemdrag').removeClass('selected_element');
        $('.bot_container').slideUp()
        const id = $(e.currentTarget).attr('id');

        this.state.list.forEach(item=>{
            if(item.name===id && item.status!=='create'){
                const idObj={id:item.id}
                this.setState(state => {
                    const delid = state.delid.concat(idObj);
                    return {
                        delid,
                    };
                })
            }
        })
        this.setState(state => {
            const list = state.list.filter((item, j) => item.name !== id);
            return {
                list,
                selectItem: '',
                selectThumb: ''
            };
        });
    }
    //select thum
    SelectItem = (e) => {
        const itemID = $(e.currentTarget).attr('id')
        this.setState(state => {
            const list = state.list.map((item) => {
                if (item.link_id === 'ListTempID') {
                    return {...item, link_id: itemID}
                }
                else if (this.state.selectItem === item.name) {
                    return {...item, link_id: itemID}
                }
                else return item
            });
            return {
                list,
                selectThumb: itemID
            };
        });


    }
    // close thumb
    closeThumb = (e) => {
        $('.bot_container').slideUp();
        $('.main_prototype.flex-center').removeClass('padding_bottom_400')
        $('.itemdrag').removeClass('selected_element');
        this.setState(state => {
            const list = state.list.filter((item, j) => item.link_id !== state.tempID);
            return {
                list,
                selectItem: '',
                selectThumb: ''
            };
        });
    }
    doneProto = (x, y) => {
        let list1 = this.state.list.filter((item, j) => item.link_id !== this.state.tempID);

         if(this.props.getDocumentation.header != this.state.header || this.props.getDocumentation.footer!=this.state.footer){
             this.props.headerFooter({
                 header: this.state.header,
                 footer: this.state.footer,
                 website_id:this.props.projId,
                 id:this.props.screenId
             })
         }
        var promises = [];
        list1 = list1.map((item, j) => {
            return {...item, x:  parseFloat(item.x)/parseFloat(this.state.scale), y:  parseFloat(item.y)/parseFloat(this.state.scale), width: parseFloat(item.width)/parseFloat(this.state.scale), height:  parseFloat(item.height)/parseFloat(this.state.scale)}
        });
        console.log(JSON.stringify(this.props.getImgLinks) )
        console.log(JSON.stringify(list1))
        list1.forEach(item => {
            if(item.status==='create'){
                delete item['status']
                // this.props.addLink(item)
                promises.push( this.props.addLink(item))
            }

            else if(item.status!=='create'  ){
                // this.props.editLink(item)
                    this.props.getImgLinks.forEach(item1=>{
                        if(item1.id == item.id && (item1.x!=item.x || item.y !=item.y || item.width !=item.width || item.height !=item.height)){
                            promises.push( this.props.editLink(item))
                        }
                    })



            }

        })
        if(this.state.delid && this.state.delid.length)
             this.state.delid.forEach(item=>{
                 // this.props.delLink(item)
                 promises.push(  this.props.delLink(item))
        })
        this.setState({
            delid:[],
        })
        const _this= this
        Promise.all(promises)
            .then(function(){
               if(x==='done'){
                   history.push(`/project/${_this.props.projId}`)
                   // _this.props.notSuccess('done proto')
                   // setTimeout(() => {
                   //     _this.props.notClear()
                   // }, 2000)
               }
               else if(x==='preview'){
                   history.push(`/project/${_this.props.projId}/preview/${_this.props.screenId}?prototype`)
               }
               else if(x==='arrow'){
                   history.push(y)
                   _this.closeThumb()
               }

            })
    }
    loadImg = (e)=>{
        // localStorage.setItem('scale','1')
        let defaultScale = sessionStorage.getItem('scale')?JSON.parse(sessionStorage.getItem('scale')):null;
        let windowWidth =$(window).width() - 600;
        let width =$('.main_img').get(0).naturalWidth
        let height =$('.main_img').get(0).naturalHeight
        // if(windowWidth <= width ){
        //     let scale = (windowWidth/$('.main_img').get(0).naturalWidth)
        //     this.setState({maxScale:scale})
        //     if(defaultScale && defaultScale <= scale){
        //         this.resizeImg(defaultScale, width)
        //     }
        //     else{
        //         this.resizeImg(scale, width)
        //         localStorage.setItem('scale', scale);
        //     }
        //
        // }
        // else{
        //     let scale = 1
        //     this.resizeImg(scale, width)
        //     localStorage.setItem('scale', scale);
        // }
        let scale;
        var scale1 =windowWidth/$('.main_img').get(0).naturalWidth;
        this.setState({footer:this.state.footer?this.state.footer:height})
        this.setState({maxScale:scale1>=1?1:scale1})
        if(scale1 > 1){
            let param = parseFloat(width/height)
            if(param > 0.3 && param < 0.7){
                scale = 0.5;
                if(defaultScale){
                    this.resizeImg(defaultScale, width)
                }
                else{
                    this.resizeImg(scale, width)
                }
            }
            else if(param <= 0.3){
                scale=1;
                if(defaultScale){
                    this.resizeImg(defaultScale, width)
                }
                else{
                    this.resizeImg(scale, width)
                }
            }
            else if(param >= 0.7){
                scale=1;
                if(defaultScale){
                    this.resizeImg(defaultScale, width)

                }
                else{
                    this.resizeImg(scale, width)
                }
            }
        }
        else{
            scale = scale1
            if(defaultScale && defaultScale <= scale){
                this.resizeImg(defaultScale, width)
            }
            else{
                this.resizeImg(scale, width)
            }
        }


    }
    resizeImg =(scale, width)=>{
        this.setState({
            scale: scale,
            imgWidth: width?(width * scale):''
        })
        sessionStorage.setItem('scale', scale);
        this.setState(state => {
            const list = this.props.getImgLinks.map((item, j) => {
                return {...item, x: parseFloat(item.x)*scale, y: parseFloat(item.y)*scale, width: parseFloat(item.width)*scale, height:  parseFloat(item.height)*scale}
            });
            return {
                list
            };
        });
    }
    resizeImg1 =(scale, width, opt)=>{
        // console.log(scale)
        // console.log(opt)
        this.setState({
            scale: scale,
            imgWidth: width?(width * scale):''
        })

        sessionStorage.setItem('scale', scale);
        this.setState(state => {
            const list = state.list.map((item, j) => {
                return {...item, x: parseFloat(item.x)/opt*scale, y: parseFloat(item.y)/opt*scale, width: parseFloat(item.width)/opt*scale, height:  parseFloat(item.height)/opt*scale}
            });
            return {
                list
            };
        });
    }
    // zoomOut = () =>{
    //     let width =$('.main_img').get(0).naturalWidth
    //     this.setState({
    //         scale:(this.state.scale - 0.05)>0.2?(this.state.scale - 0.05):0.2,
    //     }, function () {
    //         this.resizeImg(this.state.scale, width)
    //     })
    //
    // }
    // zoomIn = () =>{
    //     let width =$('.main_img').get(0).naturalWidth
    //     this.setState({
    //         scale:(this.state.maxScale - 0.05)>this.state.scale?(this.state.scale + 0.05):this.state.maxScale,
    //     }, function () {
    //         this.resizeImg(this.state.scale, width)
    //     })
    //
    // }
    zoomOut = () =>{
        let width =$('.main_img').get(0).naturalWidth
        this.setState({
            scale:(this.state.scale - 0.05)>0.2?(this.state.scale - 0.05):0.2,
            scale1:(this.state.scale - 0.05)>0.15?this.state.scale:0.2
        }, function () {
            this.resizeImg1(this.state.scale, width, this.state.scale1)
        })

    }
    zoomIn = () =>{
        let width =$('.main_img').get(0).naturalWidth
        this.setState({
            scale:(this.state.maxScale - 0.05)>this.state.scale?(this.state.scale + 0.05):this.state.maxScale,
            scale1:(this.state.maxScale - 0.05)>this.state.scale-0.05?this.state.scale:this.state.maxScale
        }, function () {
            this.resizeImg1(this.state.scale, width, this.state.scale1)
        })

    }
    render() {
        return (

            <div  id="prototype_main_container" className="screen_cont_inside">
                <div className="zoom" >
                    <div className="zoomOut" onClick={this.zoomOut}>-</div>
                    <div>{Math.round(this.state.scale * 100)}</div>
                    <div className="zoomIn" onClick={this.zoomIn}>+</div>
                </div>
                    <div className="scr_name">{this.props.getDocumentation && this.props.getDocumentation.name}</div>
                    <div className="img-container" onMouseDown={this.addDragElement} onMouseMove={this.mouseMove} onMouseUp={this.mouseUp}>
                        <Rnd
                             size={{width: '100%', height: 0}}
                             position={{x:0, y: this.state.header*this.state.scale }}
                             enableResizing={false}
                             bounds=".img-container"
                             dragAxis='y'
                             className="drag_header"
                             onDragStop={() => { this.setState({ visibHeader: false }) }}
                             onDragStart={() => { this.setState({ visibHeader: this.state.scale<1?true:false }) }}
                             onDrag={(e,d)=>{ ;  this.setState({ x:0, header: d.y/this.state.scale })  }}

                        >
                            <div className="header_proto" >
                                {this.props.getDocumentation &&
                                <div className="zoom-preview"
                                     style={{
                                         transform: this.state.visibHeader?'scale(1)':'scale(0)',
                                         backgroundImage:`url(https://imsba.com/${this.props.getDocumentation.img})`,
                                         backgroundPosition:`50% ${-this.state.header+80}px`
                                     }}
                                ></div>}
                                <div className="header_px">{Math.round(this.state.header)} px</div>
                            </div>
                        </Rnd>
                        <Rnd
                            size={{width: '100%', height: 0}}
                            position={{x:0, y: this.state.footer*this.state.scale }}
                            enableResizing={false}
                            bounds=".img-container"
                            dragAxis='y'
                            className="drag_header"
                            onDragStop={() => { this.setState({ visibFooter: false }) }}
                            onDragStart={() => { this.setState({ visibFooter: this.state.scale<1?true:false }) }}
                            onDrag={(e,d)=>{ this.setState({ x:0, footer: d.y/this.state.scale })  }}

                        >
                            <div className="header_proto footer_proto" >
                                {this.props.getDocumentation &&
                                <div className="zoom-preview"
                                     style={{
                                         transform: this.state.visibFooter?'scale(1)':'scale(0)',
                                         backgroundImage:`url(https://imsba.com/${this.props.getDocumentation.img})`,
                                         backgroundPosition:`50% ${-this.state.footer+80}px`
                                     }}
                                ></div>}
                                <div className="header_px">{Math.ceil(this.state.footer)} px</div>
                            </div>
                        </Rnd>
                        {this.props.getDocumentation && <img src={`https://imsba.com/${this.props.getDocumentation.img}`}
                                                             style={{width:this.state.imgWidth+'px', maxWidth:(window.innerWidth - 600)}}
                                                             alt="" className="main_img"/>}
                        {this.state.list ?
                            this.state.list.map((item, index) =>
                                <Rnd key={index}
                                     size={{width: item.width, height: item.height}}
                                     position={{x: item.x, y: item.y}}
                                     bounds=".img-container"
                                     onDragStop={(e, d) => {
                                         this.setState(state => {
                                             const list = state.list.map((item1, j) => {
                                                 if (j === index) {
                                                     return {...item1, x: d.x, y: d.y}
                                                 } else {
                                                     return item1;
                                                 }
                                             });

                                             return {
                                                 list
                                             };
                                         });
                                         e.preventDefault()
                                     } }
                                     onResize={(e, direction, ref, delta, position) => {
                                         this.setState(state => {
                                             const list = state.list.map((item1, j) => {
                                                 if (j === index) {
                                                     return {
                                                         ...item1, ...position,
                                                         width: (ref.style.width),
                                                         height: ref.style.height
                                                     }
                                                 } else {
                                                     return item1;
                                                 }
                                             });

                                             return {
                                                 list,
                                             };
                                         });
                                     }}
                                >
                                    <div className="itemdrag" data-id={item.name} data-select-id={item.link_id}
                                         onMouseUp={this.SelectDragItem} onMouseDown={this.SelectDragItem1}>
                                        {item.link_id !== 'ListTempID' ? <span className="del_drag" onClick={this.delDrag}
                                                                         id={item.name}>x</span> : ''}
                                    </div>
                                </Rnd>
                            )
                            : ""}
                    </div>
                    <div className="bot_container" id="bot_container">
                        <div className="d-flex" id="bot-flex">
                            <div className="close_thumb flex-center" onClick={this.closeThumb}>Close</div>

                            {this.props.getDocumentation && <div className="current_img"><img src={`https://imsba.com/${this.props.getDocumentation.img}`} alt=""/></div>}
                            {this.props.getDocumentation && this.state.thumbList &&
                            this.state.thumbList.map((item, index) => {
                                    if (item.id !== this.props.getDocumentation.id) {
                                       return <div
                                            className={this.state.selectThumb === item.id ? 'only_img selected' : 'only_img'}
                                            onClick={this.SelectItem} id={item.id} key={index}>
                                            <img src={`https://imsba.com/${item.img}`} alt=""/>
                                            <span
                                                className='select_txt'><span className="img_name_overlow">{item.name}</span><span>Selected</span></span>
                                        </div>
                                    }
                                    else {
                                        return ''
                                    }
                                }


                            )}
                        </div>
                    </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    // const {getDocumentations}=state.getDocumentations
    const {getDocumentation}=state.getDocumentation
    const {getImgLinks}=state.getImgLinks
    return {
        getDocumentation,
        getImgLinks
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getDoc: (id) => {
            dispatch(getInfo.getDocumentation(id));
        },
        getLinks: (id) => {
            dispatch(getInfo.getImgLinks(id));
        },
        addLink: (data) => {
            return new Promise((resolve, reject) => {
                resolve(dispatch(userActions.addImgLink(data)))
            })
        },
        delLink: (data) => {
            return new Promise((resolve, reject) => {
                resolve(dispatch(userActions.delImgLink(data)))
            })
        },
        editLink: (data) => {
            return new Promise((resolve, reject) => {
                resolve(dispatch(userActions.editImgLink(data)));
            })
        },
        headerFooter: (data) => {
            dispatch(userActions.headerFooter(data));
        },
        // notSuccess: (data) => {
        //     dispatch(notificationActions.success(data));
        // },
        // notClear: (data) => {
        //     dispatch(notificationActions.clear());
        // },
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(Prototype);
