import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { notification } from './notification.reducer';
import { loading } from './request';
import { modals } from './modal.reducer';
import { GetData } from './getInfo.reducer';
import { createProject } from './getInfo.reducer';
import { getProject } from './getInfo.reducer';
import { getProjects } from './getInfo.reducer';
import { getFolders } from './getInfo.reducer';
import { getUsers } from './getInfo.reducer';
import {
    getDocumentations,
    // projectAddFolder,
    getDocumentation, getImgLinks, getComments, getApi, getApiTemplates} from './getInfo.reducer';
import { loadingBarReducer } from 'react-redux-loading-bar'

const rootReducer = combineReducers({
      authentication,
      alert,
      notification,
      loading,
      modals,
      GetData,
      createProject,
      getProject,
      getProjects,
      getFolders,
      getUsers,
      getDocumentations,
    // projectAddFolder,
      getDocumentation,
      getImgLinks,
      getComments,
      getApi,
      getApiTemplates,
      loadingBar: loadingBarReducer,
});

export default rootReducer;