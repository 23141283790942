import { userService } from '../services';
import { alertActions } from './';
import { modalActions } from './';

export const getInfo = {
    getProjects,
    getProject,
    getFolders,
    getUsers,
    getDocumentations,
    getDocumentation,
    getImgLinks,
    getComments,
    getApi,
    getApiTemplates,
    getApiOne

};
function getProjects(data) {
    return dispatch => {
        dispatch(request());
        userService.getProjects('admin/websites/websites?key=', data)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getProjects) { return { type: 'GET_PROJECTS', getProjects } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getProject(id) {
    return dispatch => {
        dispatch(request());
        userService.GetInfo(`admin/websites/website/${id}?key=`, '')
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getProject) { return { type: 'GET_PROJECT', getProject } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getDocumentations(id) {
    return dispatch => {
        dispatch(request());
        userService.GetDocumentations(`admin/websites/documentations/${id}?key=`, id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getDocumentations) { return { type: 'GET_DOCUMENTATIONS', getDocumentations } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getDocumentation(id) {
    return dispatch => {
        dispatch(request());
        userService.GetDocumentations(`admin/websites/documentation/${id}?key=`, id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getDocumentation) { return { type: 'GET_DOCUMENTATION', getDocumentation } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getFolders() {
    return dispatch => {
        dispatch(request());
        userService.GetInfo('admin/websites/folders?key=', '')
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getFolders) { return { type: 'GET_FOLDERS', getFolders } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getUsers() {
    return dispatch => {
        dispatch(request());
        userService.GetInfo('admin/users/users?key=', '')
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getUsers) { return { type: 'GET_USERS', getUsers } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getImgLinks(id) {
    return dispatch => {
        dispatch(request());
        userService.GetDocumentations(`admin/websites/documentation_links/${id}?key=`, id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getImgLinks) { return { type: 'GET_IMAGE_LINKS', getImgLinks } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getComments(id) {
    return dispatch => {
        dispatch(request());
        userService.GetDocumentations(`admin/websites/documentation_comments/${id}?key=`, id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getComments) { return { type: 'GET_COMMENTS', getComments } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getApi(id) {
    return dispatch => {
        dispatch(request());
        userService.GetDocumentations(`admin/websites/documentation_apis/${id}?key=`, id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getApi) { return { type: 'GET_API', getApi } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getApiOne(id, id_item) {
    return dispatch => {
        dispatch(request());
        userService.GetDocumentations(`admin/websites/documentation_api/${id}?key=`, id)
            .then(
                data => {
                    dispatch(success(data, {id:id_item}));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getApiOne, id) { return { type: 'GET_API_ONE', getApiOne, id } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getApiTemplates(id) {
    return dispatch => {
        dispatch(request());
        userService.GetDocumentations(`admin/websites/documentation_api_templates/${id}?key=`, id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup',error));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(getApiTemplates) { return { type: 'API_TEMPLATES', getApiTemplates } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}